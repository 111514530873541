@import "@/assets/scss/styles";
.top-order-toolbar-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  .total-text {
    @include textXXL(var(--text-gray-normal), 600);
  }
  .price-text {
    margin: 0 16px 0 8px;
    @include textXXL(var(--text-gray-strongest), 700);
  }
}

.button-checkout {
  min-width: 204px;
  border: 1px solid;
  &:hover {
    border-color: transparent !important;
  }
}

.button-review {
  width: 155px;
  height: 32px;
}

.button-checkout-text {
  @include fontSetup(white, 14px, 20px, normal, 600);
  height: 100%;
  padding: var(--spacing-1) var(--spacing-3);
}
