@import "@/assets/scss/styles";
.message-error {
  @include textXXL(var(--text-gray-stronger), 400);
  margin-bottom: var(--spacing-7);
}

.not-found {
  &-container {
    img {
      height: 60%;
    }
    h2 {
      @include textDisplayM(var(--text-gray-stronger), 500);
      margin-top: 51px;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    overflow: auto;
  }
  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}