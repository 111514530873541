@import "@/assets/scss/styles";

.contact-us {
  max-width: calc(100% - var(--spacing-1));
  height: calc(100vh - 140px);

  &-text-title {
    @include textDisplayXS(var(--text-gray-stronger), 600);
  }

  &-container {
    max-width: var(--max-width);
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;

    &-top {
      margin: var(--spacing-8) var(--spacing-6) var(--spacing-4);
      padding: var(--spacing-4) 45px var(--spacing-7);
      background-color: var(--bg-base-base);
      border: 1px solid var(--border-gray-normal);

      &-row {
        display: flex;
        align-items: center;

        &.row-1 {
          gap: var(--spacing-4);
          padding-bottom: 29px;
        }

        &.row-2 {
          padding-bottom: 42px;
        }

        &.row-3 {
          padding-bottom: var(--spacing-8);
        }

        &.row-4 {
          p:first-child {
            padding-bottom: var(--spacing-2);
          }
        }
      }
    }

    &-bottom {
      margin: var(--spacing-4) var(--spacing-6) 13px;
      padding: 39px 30px var(--spacing-7) var(--spacing-12);
      background-color: var(--bg-base-base);
      border: 1px solid var(--border-gray-normal);

      &-row-1 {
        padding-bottom: var(--spacing-6);
        display: flex;
        align-items: center;
        gap: var(--spacing-2);
      }

      &-row-2 {
        display: flex;
        justify-content: space-between;
        gap: var(--spacing-6);

        &-left {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: var(--spacing-6);

          &-row-1 {
            display: flex;
            align-items: center;
            gap: var(--spacing-2);
          }

          &-row-2 {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: var(--spacing-2);

            &-left {
              grid-area: left;
            }

            &-right {
              grid-area: right;
            }
          }

          &-row-3 {
            margin-top: auto;
            gap: var(--spacing-2);

            a {
              text-decoration: underline;
            }
          }
        }

        &-right {
          flex: 1;
          display: flex;
          justify-content: end;

          img {
            object-fit: contain;
            max-width: 100%;
          }
        }
      }
    }

    &-session {
      border-top: 1px solid var(--border-gray-strong);
      padding: 14px 0px 29px;
      margin: var(--spacing-0) var(--spacing-10);
      
      p {
        @include textL(var(--text-gray-normal), 400);
      }
    }
  }
}

.text-content {
  @include textXL(var(--text-gray-stronger), 400);
}

.text-subtitle {
  @include textXL(var(--text-gray-stronger), 600);
}