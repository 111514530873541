@import "@/assets/scss/styles";

.custom-modal-support {
  &-text-box {
    padding-bottom: var(--spacing-6);
    padding: var(--spacing-2) var(--spacing-2) var(--spacing-6) var(--spacing-2);
    p {
      @include textM(var(--text-gray-stronger), 400);
    }
  }
  &-button {
    display: flex;
    justify-content: center;
    padding: var(--spacing-1) var(--spacing-0);
    gap: var(--spacing-2);
    button {
      width: 120px !important;
    }
  }
}
