@import "@/assets/scss/styles";

.quotes-detail {
  max-width: var(--max-width);
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: var(--spacing-8) var(--spacing-6);
  gap: var(--spacing-6);
  &-left {
    flex: 3;
    &-content {
      &-detail {
        background-color: white;
        &-header {
          border-bottom: 1px solid var(--border-gray-normal);
          &-wrapper {
            padding: var(--spacing-6) var(--spacing-6) var(--spacing-0)
              var(--spacing-6);
            display: flex;
          }
          &-info {
            padding: 0 var(--spacing-6) var(--spacing-6) var(--spacing-6);
            border-left: 1px solid var(--border-gray-normal);
            flex: 1;
            &-text-title {
              padding-bottom: var(--spacing-4);
              @include textS(var(--text-gray-weak), 700);
            }
            &-detail:nth-child(4) {
              padding-bottom: var(--spacing-0);
            }
            &-detail {
              padding-bottom: var(--spacing-4);
              display: flex;
              justify-content: space-between;
              &-left,
              &-right {
                flex: 1;
                p:nth-child(1) {
                  padding-bottom: var(--spacing-1-5);
                  @include textM(var(--text-gray-stronger), 600);
                }
                p:nth-child(2) {
                  @include textM(var(--text-gray-stronger), 400);
                }
              }
              &-right {
                &-estimated {
                  color: var(--text-base-success) !important;
                  font-size: 14px !important;
                  line-height: 24px !important;
                  font-style: normal !important;
                  font-weight: 600 !important;
                }
              }
            }
          }
          &-title {
            flex: 1;
            padding-right: var(--spacing-6);
            p:nth-child(1) {
              @include textDisplayXS(var(--text-gray-stronger),700);
              padding-bottom: var(--spacing-3);
            }
            &-payment {
              p:nth-child(1) {
                @include textM(var(--text-gray-strongest),700);
                padding-bottom: var(--spacing-0);
              }
              &-option {
                margin: var(--spacing-0);
                padding: var(--spacing-2) var(--spacing-6);
                li {
                  @include textM(var(--text-gray-normal),400)
                }
              }
            }
            p:nth-child(2) {
              @include textM(var(--text-gray-normal), 400);
            }
            &-subtext {
              width: 100%;
              span:nth-child(1) {
                @include textM(var(--text-gray-strongest), 400);
              }
              span:nth-child(2) {
                @include textM(var(--text-gray-strongest), 400);
              }
            }
          }
          &-status {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: right;
            p:nth-child(1) {
              @include textL(var(--text-gray-normal), 400);
            }
            p:nth-child(2) {
              @include textL(var(--text-gray-stronger), 400);
            }
          }
        }
        &-additional {
          margin-left: 9%;
          padding: var(--spacing-4) var(--spacing-6) var(--spacing-4) 0;
          &-service {
            padding: var(--spacing-4);
            background-color: var(--bg-base-bg);
            &-title {
              @include textM(var(--text-gray-strongest), 600);
              padding-bottom: 5px;
            }
            &-detail {
              &-info {
                display: flex;
                justify-content: space-between;
                align-items: center;
                p {
                  @include textM(var(--text-gray-stronger), 400);
                  padding-bottom: 5px;
                  flex: 1;
                }
                p:nth-child(2) {
                  text-align: right;
                }
              }
            }
          }
        }
        &-product {
          background-color: white;
          &-list {
            padding: var(--spacing-0) var(--spacing-6) var(--spacing-6)
              var(--spacing-6);
          }
          &-header {
            display: grid;
            background-color: var(--bg-base-bg);
            grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
            column-gap: 16px;
            justify-content: space-between;
            padding: var(--spacing-4) var(--spacing-6);
            border-bottom: 1px solid var(--border-gray-normal);

            @media (max-width: 1380px) {
              grid-template-columns: 1.5fr 1fr 0.8fr 0.6fr 0.6fr;
            }
            @media (min-width: 1267px) and (max-width: 1350px) {
              grid-template-columns: 1.45fr 1fr 1fr 0.5fr 1fr;
            }
            @media (min-width: 1025px) and (max-width: 1266px) {
              grid-template-columns: 2fr 1.2fr 1.2fr 0.5fr 1fr;
            }
            @media (max-width: 1024px) {
              grid-template-columns: 2fr 1fr 1fr 0.5fr 1fr;
            }
            p {
              @include textS(var(--text-gray-normal), 700);
            }
            p:nth-child(5) {
              text-align: right;
            }
          }
        }
        &-estimated {
          display: flex;
          justify-content: end;
          padding-top: var(--spacing-7);
          p {
            @include textL(var(--text-gray-stronger), 400);
          }
        }
      }
      &-info {
        padding: var(--spacing-6);
        background-color: white;
        &-address {
          display: flex;
          &-title {
            @include fontSetup(var(--text-gray-weak), 14px, 24px, normal, 700);
            padding-bottom: var(--spacing-4);
          }
          &-shipping,
          &-billing {
            flex: 1;
            &-detail {
              display: flex;
              flex-direction: column;
              gap: var(--spacing-1);
              p {
                @include textM(var(--text-gray-stronger), 400);
              }
            }
          }
        }
      }
      &-note {
        margin-top: var(--spacing-6);
        &-header {
          display: flex;
          align-items: center;
          gap: var(--spacing-1-5);
          padding-bottom: var(--spacing-3);
          p {
            @include textM(var(--text-gray-stronger), 400);
          }
        }
        ol {
          padding-left: var(--spacing-10) !important;
        }
      }
      &-files {
        display: flex;
        cursor: default;
        padding-top: var(--spacing-3);
        background-color: white;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-1);
        &-item {
          p {
            max-width: 193px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include textS(var(--text-gray-stronger), 500);
          }
          border-radius: 99999px;
          padding: 0 var(--spacing-2);
          align-items: center;
          background-color: var(--bg-gray-weaker);
          display: flex;
          span {
            cursor: pointer;
          }
        }
      }
    }
  }

  &-right {
    flex: 1;
  }

  @media (max-width: 1280px) {
    &-left {
      flex: 2;
    }
    &-right {
      flex: 1;
    }
  }
}
