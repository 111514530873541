@import "@/assets/scss/styles";

.order-summary {
  &-warning {
    margin-top: var(--spacing-4);
    background-color: var(--bg-warning-tonal-weaker);
    border-radius: var(--spacing-1);
    border: 1px solid var(--border-base-warning);
    padding: var(--spacing-4);
    svg {
      font-size: 20px;
      fill: var(--text-gray-strongest);
    }
    &-title {
      p {
        @include textL(var(--text-gray-stronger), 600);
      }
      align-items: center;
      display: flex;
      gap: var(--spacing-2);
    }
    &-content {
      margin-top: var(--spacing-4);
      @include textS(var(--text-gray-strongest), 400);
    }
    &-btn {
      padding: var(--spacing-0-5) var(--spacing-3) !important;
    }
  }
  &-container {
    background-color: var(--bg-base-base);
    border-radius: 6px;
    border: 1px solid var(--border-gray-normal);
    padding: var(--spacing-4);
  }
  &-section {
    padding: var(--spacing-4) var(--spacing-0);
    border-top: 1px solid var(--border-normal);
    display: flex;
    gap: var(--spacing-3);
    flex-direction: column;
    &-item {
      display: flex;
      gap: var(--spacing-3);
      align-items: center;
      p:nth-child(1) {
        @include fontSetup(var(--text-gray-weak), 14px, 24px, normal, 600);
        padding-bottom: var(--spacing-1);
      }
      p:nth-child(2) {
        @include fontSetup(var(--text-gray-strongest), 14px, 24px, normal, 600);
      }
    }
  }
  &-title {
    h3 {
      @include textXXL(var(--text-gray-stronger), 600);
    }
    // p {
    //   @include textM(var(--text-base-success), 600);
    // }
    margin: var(--spacing-2) 0 var(--spacing-4) 0;
  }
  &-item {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-2);
    padding-bottom: var(--spacing-3);
    p {
      @include textS(var(--text-gray-normal), 600);
    }
    &-info {
      display: flex;
      img {
        padding: 1px 5px;
      }
      p {
        @include textS(var(--text-gray-strongest), 600);
      }
    }
  }
  &-price {
    padding: var(--spacing-4) 0;
    border-bottom: 1px solid var(--bg-base-gray);
    border-top: 1px solid var(--bg-base-gray);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
    &-section {
      display: flex;
      justify-content: space-between;
      .loading {
        padding-top: 0 !important;
      }
      p {
        @include textM(var(--text-gray-normal), 400);
      }
      p:last-child {
        @include textM(var(--text-gray-strongest), 600);
      }
    }
  }
  &-total-price {
    display: flex;
    justify-content: space-between;
    padding-top: var(--spacing-4);
    p {
      @include textL(var(--text-gray-stronger), 400);
    }
    p:last-child {
      @include textXL(var(--text-gray-stronger), 700);
    }
  }
  &-btn {
    width: 100%;
    margin-top: var(--spacing-4);
  }
}
