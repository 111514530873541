@import "@/assets/scss/styles.scss";

.profile-layout {
  height: 100vh;
  display: flex;
  background-color: var(--bg-base-bg);

  .header-text {
    @include textDisplayXS(var(--text-gray-stronger), 700);
    padding: 14px var(--spacing-6);
  }

  &-content {
    height: 100%;
    width: 100%;
  }

  .content {
    height: 100%;
    width: 100%;
    overflow: auto;
    flex: 2;

    &-wrapper {
      max-width: var(--max-width);
      margin: 0 auto;
      display: flex;
      height: calc(100% - 60px);
      gap: var(--spacing-6);
    }
  }

  .left {
    flex: 1;
  }
}
