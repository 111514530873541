@import "@/assets/scss/styles.scss";

.custom-badge {
  &-tonal {
    &-extrasmall {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-tonal-weaker) !important;
          @include textXS(var(--text-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-base-gray) !important;
          @include textXS(var(--text-gray-stronger), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-tonal-weaker) !important;
          @include textXS(var(--text-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-tonal-weaker) !important;
          @include textXS(var(--text-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-tonal-weaker) !important;
          @include textXS(var(--bg-success-solid-normal), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-tonal-weaker) !important;
          @include textXS(var(--text-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-small {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-tonal-weaker) !important;
          @include textS(var(--text-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-base-gray) !important;
          @include textS(var(--text-gray-stronger), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-tonal-weaker) !important;
          @include textS(var(--text-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-tonal-weaker) !important;
          @include textS(var(--text-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-tonal-weaker) !important;
          @include textS(var(--bg-success-solid-normal), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-tonal-weaker) !important;
          @include textS(var(--text-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-medium {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-tonal-weaker) !important;
          @include textM(var(--text-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-base-gray) !important;
          @include textM(var(--text-gray-stronger), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-tonal-weaker) !important;
          @include textM(var(--text-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-tonal-weaker) !important;
          @include textM(var(--text-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-tonal-weaker) !important;
          @include textM(var(--bg-success-solid-normal), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-tonal-weaker) !important;
          @include textM(var(--text-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-large {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-tonal-weaker) !important;
          @include textL(var(--text-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-base-gray) !important;
          @include textL(var(--text-gray-stronger), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-tonal-weaker) !important;
          @include textL(var(--text-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-tonal-weaker) !important;
          @include textL(var(--text-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-tonal-weaker) !important;
          @include textL(var(--bg-success-solid-normal), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-tonal-weaker) !important;
          @include textL(var(--text-base-info), 500);
          border-radius: 9999px;
        }
      }
    }
  }

  &-outline {
    &-extrasmall {
      &-brand {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-brand) !important;
          @include textXS(var(--border-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-gray-button) !important;
          @include textXS(var(--border-gray-button), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-warning) !important;
          @include textXS(var(--border-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-danger) !important;
          @include textXS(var(--border-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-success) !important;
          @include textXS(var(--border-base-success), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-info) !important;
          @include textXS(var(--border-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-small {
      &-brand {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-brand) !important;
          @include textS(var(--border-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-gray-button) !important;
          @include textS(var(--border-gray-button), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-warning) !important;
          @include textS(var(--border-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-danger) !important;
          @include textS(var(--border-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-success) !important;
          @include textS(var(--border-base-success), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-info) !important;
          @include textS(var(--border-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-medium {
      &-brand {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-brand) !important;
          @include textM(var(--border-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-gray-button) !important;
          @include textM(var(--border-gray-button), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-warning) !important;
          @include textM(var(--border-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-danger) !important;
          @include textM(var(--border-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-success) !important;
          @include textM(var(--border-base-success), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-info) !important;
          @include textM(var(--border-base-info), 500);
          border-radius: 9999px;
        }
      }
    }

    &-large {
      &-brand {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-brand) !important;
          @include textL(var(--border-base-brand), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-gray-button) !important;
          @include textL(var(--border-gray-button), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-warning) !important;
          @include textL(var(--border-base-warning), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-danger) !important;
          @include textL(var(--border-base-danger), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-success) !important;
          @include textL(var(--border-base-success), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: transparent !important;
          border: 1px solid var(--border-base-info) !important;
          @include textL(var(--border-base-info), 500);
          border-radius: 9999px;
        }
      }
    }
  }

  &-solid {
    &-extrasmall {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-solid-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-gray-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-solid-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-solid-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-solid-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-solid-normal) !important;
          @include textXS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }
    }

    &-small {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-solid-normal) !important;
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-gray-normal) !important;
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-solid-normal) !important;
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-solid-normal) !important;
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-solid-normal) !important;
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          @include textS(var(--bg-base-base), 500);
          border-radius: 9999px;
          background: var(--bg-info-solid-normal) !important;
          margin: 6px;
        }
      }
    }

    &-medium {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-solid-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-gray-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-solid-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-solid-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-solid-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-solid-normal) !important;
          @include textM(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }
    }

    &-large {
      &-brand {
        & .custom-badge-container {
          background: var(--bg-brand-solid-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-role {
        & .custom-badge-container {
          background: var(--bg-gray-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-warning {
        & .custom-badge-container {
          background: var(--bg-warning-solid-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-error {
        & .custom-badge-container {
          background: var(--bg-danger-solid-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-success {
        & .custom-badge-container {
          background: var(--bg-success-solid-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }

      &-inprogress {
        & .custom-badge-container {
          background: var(--bg-info-solid-normal) !important;
          @include textL(var(--bg-base-base), 500);
          border-radius: 9999px;
        }
      }
    }
  }
}

.icon-circle {
  margin-right: var(--spacing-0-5);
}