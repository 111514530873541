@import "@/assets/scss/styles.scss";

.select-wrapper {
  position: relative;
  width: fit-content;
  .ant-select-arrow {
    color: var(--bg-gray-normal);
  }
  .prefix-icon-wrapper {
    position: absolute;
    left: var(--spacing-1);
    top: 0;
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .suffix-icon-wrapper {
    right: 0;
    top: 0;
    position: absolute;
    z-index: 1;
    width: 3rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      fill: var(--bg-gray-normal);
      height: 11px;
    }
  }
}
.select-wrapper:has(.prefix-icon-wrapper) {
  .ant-select-selection-item,
  .select-dropdown .placeholder-text {
    padding-left: 30px !important;
  }
}

.select-dropdown {
  height: 100%;
  .ant-select-selector {
    border-radius: var(--spacing-1);
    padding-inline: 10px !important;
    border: 1px solid #d9d9d9;
    padding: var(--spacing-1-5) var(--spacing-2-5) !important;
    outline: none !important;
  }

  & .ant-select-selection-item {
    @include textM(var(--text-gray-stronger), 500);
    line-height: 24px !important;
    padding-inline-end: 0 !important;
  }

  & .ant-select-selection-placeholder {
    padding-inline-end: 0 !important;
  }

  .placeholder-text {
    @include textM(var(--text-gray-normal), 400);
  }
}

.dropdown-menu {
  .ant-select-item {
    border-radius: 0 !important;
    padding: 10px var(--spacing-3) !important;
  }

  .item-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &.flex-start {
      align-items: flex-start;
    }
    &.center {
      align-items: center;
    }
  }

  .ant-select-item-option-state {
    display: none !important;
  }

  &.ant-select-dropdown .ant-select-item-option-selected {
    background-color: var(--bg-brand-tonal-weaker) !important;
  }

  &.ant-select-dropdown .ant-select-item-option-active {
    background-color: var(--bg-brand-tonal-weaker) !important;
  }

  .ant-select-item-option-state {
    & .anticon-check {
      color: var(--bg-gray-strongest) !important;
    }
  }

  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    outline: none !important;
    border-radius: var(--spacing-0-5);
    box-shadow: none !important;
  }

  &.multiple {
    .ant-select-item-option-state {
      display: none !important;
    }
  }

  .clear-button-text {
    @include textS(var(--text-gray-stronger), 500);
  }

  .count-number-text {
    @include textS(var(--text-gray-normal), 400);
  }

  .item-text {
    @include textM(var(--text-gray-stronger), 500);
  }

  .sub-item-text {
    @include textS(var(--text-gray-normal), 400);
  }

  .add-btn-text {
    @include textM(var(--text-base-brand), 600);
  }

  .not-found-text {
    @include textM(var(--text-gray-normal), 400, italic);
    padding: var(--spacing-3) 0;
  }

  .ant-btn-text.ant-btn-dangerous {
    color: var(--text-base-brand) !important;
  }

  .footer-dropdown {
    border-top: 1px solid var(--border-gray-strong) !important;
  }

  .ant-btn-text {
    &:hover {
      background: none !important;
    }
  }

  .custom-placeholder .anticon {
    margin-right: var(--spacing-2);
  }

  .placeholder-search-text {
    @include textM(var(--text-gray-normal), 400);
  }
}
