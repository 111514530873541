@import "@/assets/scss/styles.scss";

.shipping-detail {
    padding-bottom: var(--spacing-8);
    &-title {
        @include textL(var(--text-gray-stronger),400);
        padding-bottom: var(--spacing-1);
    }
    &-content {
        background-color: var(--bg-base-bg);
        border: 1px solid var(--border-gray-normal);
        width: 50%;
        padding: var(--spacing-3);
        p {
            @include textM(var(--text-gray-stronger),400)
        }
    }
}