@import "@/assets/scss/styles.scss";

.upload-file {
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-4);
  width: 100%;
  cursor: pointer;
  .loading {
    padding-top: 0 !important;
  }
  & .ant-upload.ant-upload-select {
    width: 100% !important;
    height: var(--spacing-12) !important;
    background-color: var(--bg-base-base) !important;
    border: none;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%239DA0B2FF' stroke-width='1' stroke-dasharray='7%2c 6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
    padding: var(--spacing-3) 0;
    border-radius: var(--spacing-2);
    &:hover {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23000000FF' stroke-width='1' stroke-dasharray='7%2c 6' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
    }
  }

  & .file-container {
    margin-top: var(--spacing-4);
    display: flex;
    align-items: center;
    gap: var(--spacing-6);
    justify-content: space-between;
    border: 1px solid var(--border-gray-strong);
    border-radius: var(--spacing-1);
    padding: var(--spacing-2) var(--spacing-3);

    &.ant-upload-item-error {
      border-color: var(--border-base-danger);
    }

    &-info {
      display: flex;
      align-items: center;
      &-name {
        max-width: 100px;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        @include textS(var(--text-gray-stronger), 400)
      }

      &-size {
        @include textXS(var(--text-gray-normal), 400)
      }

      &-btn {
        width: var(--spacing-5);
        height: var(--spacing-5);
        pointer-events: none !important;
        margin-right: var(--spacing-2);
      }
    }

    &-progress {
      width: 100%;
      display: flex;
      align-items: center;
      gap: var(--spacing-6);

      &.hidden {
        display: none;
      }

      &-percent {
        @include textS(var(--text-gray-stronger), 500)
      }

      & .ant-progress .ant-progress-outer .ant-progress-inner .ant-progress-bg {
        background-color: var(--bg-gray-normal) !important;
      }

      & .ant-progress .ant-progress-outer .ant-progress-text {
        display: none;
      }
    }

    &-remove {
      &-btn {
        width: var(--spacing-6);
        height: var(--spacing-6);
      }
    }
  }

  & .upload-container {
    display: flex;
    align-items: center;
    justify-content: center;
    & .btn-upload {
      border-radius: var(--spacing-1) !important;
      width: var(--spacing-6) !important;
      margin-right: var(--spacing-2);
      pointer-events: none;
    }

    & .upload-text {
      @include textS(var(--text-gray-stronger), 600)
    }
  }
}