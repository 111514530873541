.phone-input {
  &-wrapper {
    display: flex;
    align-self: center;
    .ant-select-dropdown {
      width: unset !important;
    }
    & .ant-select-selection-item,
    .ant-select-selection-placeholder {
      text-align: center;
    }
    .select-dropdown .ant-select-selector {
      padding: var(--spacing-3) 6px !important;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .ant-input {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-left: none;
    }
  }
  &-dropdown {
    width: 30% !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
