@import "@/assets/scss/styles";

.quote-item-box {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
  column-gap: 16px;
  justify-content: space-between;
  padding: var(--spacing-3) 0;
  border-bottom: 1px solid var(--border-gray-normal);

  @media (max-width: 1380px) {
    grid-template-columns: 1.5fr 1fr 1fr 0.6fr 0.6fr;
  }
  @media (min-width: 1267px) and (max-width: 1350px) {
    grid-template-columns: 1.45fr 1fr 1fr 0.5fr 1fr;
  }
  @media (min-width: 1025px) and (max-width: 1266px) {
    grid-template-columns: 2fr 1.2fr 1.2fr 0.5fr 1fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 2fr 1fr 1fr 0.5fr 1fr;
  }
  &-col-1 {
    display: flex;
    min-width: 100px;
    gap: var(--spacing-3);
    &-title {
      white-space: normal;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      max-width: 200px;
      @include textM(var(--text-gray-strongest), 600);
      padding-bottom: var(--spacing-1);
      @media (min-width: 1441px) and (max-width: 1600px) {
        max-width: 150px;
      }
      @media (min-width: 1025px) and (max-width: 1440px) {
        max-width: 110px;
      }
      @media (max-width: 1024px) {
        max-width: 76px;
      }
    }
    &-size {
      width: fit-content;
      display: flex;
      align-items: center;
      p {
        padding: 0 !important;
        @include textM(var(--text-gray-normal), 400);
      }
    }
    &-volume {
      width: fit-content;
      p {
        padding-bottom: 0 !important;
        @include textM(var(--text-gray-stronger), 400);
      }
    }
    .quote-drawings {
      margin: var(--spacing-2) 0;
      display: flex;
      cursor: default;
      align-items: center;
      flex-wrap: wrap;
      gap: var(--spacing-1);
      &-item {
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include textS(var(--text-gray-stronger), 500);
        }
        border-radius: 99999px;
        padding: 0 var(--spacing-2);
        align-items: center;
        background-color: var(--bg-gray-weaker);
        display: flex;
        span {
          cursor: pointer;
        }
        @media (min-width: 1441px) and (max-width: 1600px) {
          max-width: 150px;
        }
        @media (min-width: 1025px) and (max-width: 1440px) {
          max-width: 96px;
        }
        @media (max-width: 1024px) {
          max-width: 76px;
        }
      }
    }
    @media (max-width: 1400px) {
      &-size {
        max-width: 120px;
      }
      &-title {
        max-width: 150px;
      }
    }
    @media (max-width: 1280px) {
      &-size {
        max-width: 100px;
      }
      &-title {
        max-width: 100px;
      }
    }
    @media (max-width: 1080px) {
      &-size {
        max-width: 80px;
      }
      &-title {
        max-width: 80px;
      }
    }
  }
  &-col-2 {
    p {
      @include textM(var(--text-gray-stronger), 400);
      padding-bottom: var(--spacing-1);
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
    }
    @media (max-width: 1400px) {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
      }
    }
    @media (max-width: 1080px) {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100px;
      }
    }
  }
  &-col-3 {
    p {
      text-align: center;
      @include textM(var(--text-gray-stronger), 400);
    }
  }
  &-col-4 {
    text-align: right;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    p {
      @include fontSetup(var(--text-gray-stronger), 18px, 28px, normal, 700);
      padding-bottom: var(--spacing-1);
    }
    p:nth-child(2) {
      @include textS(var(--text-gray-normal), 400);
    }
    &-badge {
      position: absolute;
      width: max-content;
      bottom: 0;
      right: 0;

      p {
        @include textM(var(--success-700), 500);
        padding: 0;
      }
    }
  }
  &-col-5 {
    align-self: self-start;
    max-width: 205px;
    p {
      padding-bottom: var(--spacing-1);
      @include textM(var(--text-gray-stronger), 400);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media (min-width: 1400px) and (max-width: 1700px) {
      max-width: 160px;
    }
    @media (min-width: 1200px) and (max-width: 1399px) {
      max-width: 130px;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
      max-width: 100px;
    }
  }
}
