@import '@/assets/scss/styles';

.custom-table {
    .ant-table {
        scrollbar-color: unset;
    }
    .ant-table-thead {
        border: 1px solid var(--border-gray-normal);
        tr {
            th {
                padding: var(--spacing-3) var(--spacing-4);
                @include textM(var(--text-gray-normal),700);
                line-height: var(--spacing-6) !important;
            }
        }
    }

    .ant-table-tbody {
        tr  {
            td {
                border: none;
                padding: var(--spacing-3) var(--spacing-4);
            }
        }
        > tr.ant-table-placeholder {
            background-color: var(--bg-base-bg-2);
            &:hover {
                th,td {
                    background-color: var(--bg-base-bg-2);
                }
            }
        }
    }
}

.row {
    @include textL(var(--text-gray-stronger),500)
}

.even-row-bg {
    @include textL(var(--text-gray-stronger),500);
    background-color: var(--bg-base-bg-2)
}
