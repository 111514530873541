@import "@/assets/scss/styles";

.custom-dragger {
  padding-bottom: var(--spacing-4);
  width: 100%;
  height: 502px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .ant-upload-drag:not(.ant-upload-disabled):hover {
    .ant-upload {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23000000FF' stroke-width='1' stroke-dasharray='8%2c 10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: var(--spacing-2);
      cursor: pointer;
    }
  }

  .ant-upload {
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%239DA0B2FF' stroke-width='1' stroke-dasharray='8%2c 10' stroke-dashoffset='7' stroke-linecap='square'/%3e%3c/svg%3e");
    border-radius: var(--spacing-2);
    background-color: var(--bg-base-base);
    border: none;
    padding: var(--spacing-0) !important;
  }

  .ant-upload-list {
    width: 100%;
    margin-top: var(--spacing-4);
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: var(--spacing-4);

    &::before {
      display: none !important;
    }

    & .error-text {
      @include textS(var(--text-base-danger), 400);
    }

    & .file-container {
      display: flex;
      gap: var(--spacing-6);
      border: 1px solid var(--border-gray-strong);
      border-radius: var(--spacing-1);
      padding: var(--spacing-2) var(--spacing-3);
      &-uploading {
        align-items: center !important;
      }
      &.ant-upload-item-error {
        border-color: var(--border-base-danger);
      }

      &-info {
        display: flex;
        flex: 1;

        &-detail {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }

        &-name {
          overflow: hidden;
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          @include textL(var(--text-gray-stronger), 500);
        }

        &-size {
          @include textM(var(--text-gray-normal), 400);
        }

        &-btn {
          width: var(--spacing-6);
          height: var(--spacing-6);
          pointer-events: none !important;
          margin-right: var(--spacing-2);
        }
      }

      &-progress {
        height: var(--spacing-6);
        width: 100%;
        display: flex;
        align-items: center;
        gap: var(--spacing-6);

        &.hidden {
          display: none;
        }

        &-percent {
          @include textS(var(--text-gray-stronger), 500);
        }

        &
          .ant-progress
          .ant-progress-outer
          .ant-progress-inner
          .ant-progress-bg {
          background-color: var(--bg-gray-normal) !important;
        }

        & .ant-progress .ant-progress-outer .ant-progress-text {
          display: none;
        }
      }

      &-remove {
        .loading {
          padding-top: var(--spacing-0) !important;
        }
        &-btn {
          width: var(--spacing-6);
          height: var(--spacing-6);
        }
      }
    }
    @media (max-width: 1200px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr 1fr;
    }
  }
}

.upload-content {
  text-align: center;
  padding: 22px 0;
  img,
  p {
    margin-bottom: var(--spacing-3);
  }
}

.upload-hint {
  margin-bottom: var(--spacing-0) !important;
}

.upload-icon {
  background-color: var(--bg-brand-tonal-normal);
  color: var(--primary-800);
  padding: var(--spacing-3);
  border-radius: var(--spacing-1-5);
  font-size: var(--spacing-6);
}

.ant-upload-drag-icon > * {
  background-color: red;
  color: white;
}

.upload-text {
  @include textL(var(--text-gray-stronger), 500);
}

.upload-text > span {
  color: var(--text-base-brand);
  font-family: "Open Sans";
}

.upload-hint {
  @include textM(var(--text-gray-stronger), 400);
}

.upload-overlay {
  background: transparent;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  cursor: pointer;
}
