@import "@/assets/scss/styles";

.edit-specification-drawer {
  &-infor {
    border-bottom: 1px solid var(--border-normal) !important;
    &-wrapper {
      display: flex;
      align-items: flex-start;
      gap: 18px;
      padding: var(--spacing-4);
    }
    &-img {
      height: 42px;
      width: 57px;
    }
    &-title {
      h3 {
        @include textM(var(--text-gray-strongest), 600);
        padding-bottom: var(--spacing-0-5);
      }
      h5 {
        @include textS(var(--text-gray-normal), 400);
      }
    }
  }
  .custom-drawer-header {
    padding: var(--spacing-2) 0 !important;
    border-bottom: 1px solid var(--border-normal) !important;
    button {
      color: var(--bg-gray-strongest);
    }
  }
  .custom-drawer-body {
    padding: 0 !important;
  }
  .ant-drawer-header-title {
    padding: 0 var(--spacing-4) !important;
    border-bottom: 0 !important;
    .ant-drawer-title {
      @include textXL(var(--text-gray-strongest), 600);
    }
  }
  & .drawer-header {
    & .subtitle {
      @include textL(var(--text-gray-normal), 600);
    }

    & .ant-avatar {
      width: 52px;
      height: 52px;
    }
  }
}
