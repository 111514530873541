@import "@/assets/scss/styles";

.shipping-method-box {
  padding: var(--spacing-3);
  display: flex;
  justify-content: space-between;
  &-detail {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-3);
  }
}
