@import "@/assets/scss/mixins";
@import "@/assets/scss/variables";

.note-container {
  display: flex;
  background-color: white;
  height: 100px;

  &-form {
    padding: var(--spacing-4);
  }

  &-text {
    @include textM(var(--text-gray-stronger), 400);
    padding-bottom: var(--spacing-1);
  }
}

.note-textfield {
  @include textM(var(--text-gray-normal), 400);
  padding-top: 8px;
  padding-bottom: 8px;
}
