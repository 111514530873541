@import '@/assets/scss/variables';
@import '@/assets/scss/mixins';
.textarea {
    position: relative;
    &-wrapper {
        position: relative;
            .ant-input-outlined {
                @include textM(var(--text-gray-stronger),500);
                border-radius: var(--spacing-0-5);
                &:active {
                    border-color: var(--border-base-info);
                    box-shadow: var(--drop-shadow-info-focus-var(--spacing-0-5)-200);
                    outline: 0;
                    background-color: var(--bg-base-base);
                }
                &:hover {
                    border-color: var(--border-base-info);
                    background-color: var(--bg-base-base);
                }
                &:disabled {
                    @include textM(var(--text-gray-normal),400);
                    border-color: var(--bg-base-gray);
                    background-color: var(--bg-base-gray);
                }
                .ant-input {
                    @include textM(var(--text-gray-stronger),500);
                }
            }
            .ant-input-textarea-show-count {
                .ant-input-data-count {
                    @include textS(var(--text-gray-normal), 400);
                    top: -23px;
                    bottom: 0;
                }
            }
        }
        .error {
            .ant-input-outlined {
                border: 1px solid var(--border-base-danger);
                box-shadow: var(--drop-shadow-danger-focus-200);
            }
            .ant-input-disabled {
                border-color: var(--bg-base-gray);
                background-color: var(--bg-base-gray);
                box-shadow: none;
            }
        }
}
