.custom-checkbox {
  &.small {
    & .ant-checkbox-inner {
      width: var(--spacing-4) !important;
      height: var(--spacing-4) !important;
    }
  }

  &.medium {
    & .ant-checkbox-inner {
      width: var(--spacing-5) !important;
      height: var(--spacing-5) !important;
    }
  }

  & .ant-checkbox-checked {
    & .ant-checkbox-inner {
      border-color: var(--bg-brand-solid-normal) !important;
      background-color: var(--bg-brand-solid-normal) !important;
    }
  }

  & .ant-checkbox:hover .ant-checkbox-inner {
    box-shadow: var(--drop-shadow-danger-focus-200) !important;
    border-color: transparent !important;
  }

  & .ant-checkbox-checked:active .ant-checkbox-inner {
    background-color: var(--bg-brand-solid-stronger) !important;
    box-shadow: none !important;
  }

  & .ant-checkbox-indeterminate.ant-checkbox-checked:active .ant-checkbox-inner {
    background-color: var(--bg-brand-tonal-weaker) !important;
    box-shadow: none !important;
  }

  & .ant-checkbox:not(.ant-checkbox-checked):active .ant-checkbox-inner {
    background-color: var(--bg-base-gray) !important;
    box-shadow: none !important;
  }

  & .ant-checkbox.ant-checkbox-indeterminate:not(.ant-checkbox-checked):active .ant-checkbox-inner {
    background-color: var(--bg-brand-tonal-weaker) !important;
    box-shadow: none !important;
  }

  & .ant-checkbox-indeterminate .ant-checkbox-inner:after {
    background-color: var(--bg-brand-solid-normal) !important;
    box-shadow: none !important;
  }

  &.ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color: transparent !important;
  }
}