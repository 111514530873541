@import "@/assets/scss/styles";

.border-payment {
  border: 1px solid var(--border-gray-normal);
  padding: var(--spacing-4);
}

.payment-method {
  display: flex;
  justify-content: space-between;

  &-text-field {
    @include textM(var(--text-gray-strongest), 600);
    padding-bottom: var(--spacing-2);
  }

  &-wrapper {
    padding: var(--spacing-4);
  }
  &-left {
    display: flex;
    flex-direction: column;
    flex: 1;
    &-radio {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-2);
      .title {
        @include textM(var(--text-gray-normal),700);
        padding: var(--spacing-0) var(--spacing-0) var(--spacing-3) var(--spacing-0);
      }
      .footer {
        padding: 0;
        .left {
          text-align: left;
          padding: var(--spacing-3) var(--spacing-0) var(--spacing-0) var(--spacing-0);
          p {
            @include textXS(var(--text-gray-stronger),600);
          }
        }
      }
      .stripe-wrapper {
        padding: var(--spacing-4) var(--spacing-0);
      }
      div {
        .ant-radio-wrapper {
          padding-bottom: var(--spacing-2)
        }
      }
      span {
        @include textM(var(--text-gray-stronger), 600);
      }
    }
  }
  &-right {
    display: flex;
    flex-direction: column;
    justify-content: end;
    flex: 1.5;
    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid var(--border-gray-strong);
      padding: var(--spacing-3);
      border-radius: 4px;
      height: 22px;
    }
    &-content-error {
      border-color: #ff4d4f;
    }
    &-po {
      margin-bottom: var(--spacing-2);
    }
  }
}

.payment-method-title {
  @include textXXL(var(--text-gray-stronger), 700);
  padding-bottom: var(--spacing-2);
}

.pb-12 {
  padding-bottom: var(--spacing-3);
}

.pb-8 {
  padding-bottom: var(--spacing-2);
}

.billing-info {
  &-box {
    border: 1px solid var(--border-gray-normal);
    padding: var(--spacing-4);
    margin-top: var(--spacing-3);
  }

  &-title {
    @include textXL(var(--text-gray-stronger), 700);
    padding-bottom: var(--spacing-2);
  }
}

.item-render-pdf {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);
  width: fit-content;
  .icon-box {
    &-render {
      color: black;
      cursor: pointer;
      font-size: 10px;
      vertical-align: middle;
    }
  }
  p {
    @include textM(var(--text-gray-stronger), 400);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 220px;
  }
}

.upload-pdf {
  .item-container {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-1);
    width: 100%;
  }
  .upload-btn .title-upload {
    color: var(--text-gray-stronger) !important;
  }
}
