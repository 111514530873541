@import '@/assets/scss/styles';
.modal-resize-container {
    .custom-modal-header .ant-modal-title {
      @include textXXL(var(--text-gray-strongest),700)
    }
    .custom-modal-body {
      padding: var(--spacing-4) var(--spacing-6) !important;
    }
    &-box {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      &-btn {
        width: 120px;
        align-self: center;
      }
    }
  }

.modal-resize-container .ant-modal-close {
  top: 15px;
}
