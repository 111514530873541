@import "@/assets/scss/styles";

.col {
  padding: var(--spacing-10) var(--spacing-4) var(--spacing-4) var(--spacing-4);
  background-color: white;
  margin-top: var(--spacing-2);
  position: relative;
  border: 1px solid transparent;

  &:hover,
  &:checked,
  &-selected {
    background-color: var(--bg-base-bg);
    border: 1px solid var(--border-gray-button);
    cursor: pointer;
  }

  &:not(:checked):not(&-selected):hover {
    border: 1px solid var(--border-gray-strong);
    cursor: pointer;
  }

  &:hover {
    .col-1 > :first-child {
      visibility: visible;
    }
    .edit-btn {
      visibility: visible;
    }
    .col-6-action {
      .edit-btn {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}
.corner-bottom-right-bevel {
  border-width: var(--spacing-8) var(--spacing-8) 0 0;
  border-style: solid;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  &-loading {
    border-color: var(--bg-gray-weaker) transparent transparent transparent;
  }
  &-warning {
    border-color: var(--bg-warning-tonal-weaker) transparent transparent
      transparent;
  }
  &-success {
    border-color: var(--bg-success-tonal-weaker) transparent transparent
      transparent;
  }
  &-error {
    border-color: var(--bg-danger-tonal-weaker) transparent transparent
      transparent;
  }
  &-review {
    border-color: var(--bg-gray-weaker) transparent transparent transparent;
  }
}
.product-tag {
  position: absolute;
  top: 0;
  cursor: default;
  left: 0;
  p {
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    z-index: 1;
    position: relative;
    padding: 6px var(--spacing-3);
    @include textM(var(--text-gray-stronger), 600);
  }
}
.edit-container {
  position: absolute;
  top: var(--spacing-2);
  cursor: pointer;
  right: var(--spacing-4);
  color: var(--brand-700);

  .edit-btn {
    visibility: hidden;
  }
  .icon-loading {
    visibility: visible;
  }
}

.container {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
  column-gap: 16px;
  justify-content: space-between;
  align-items: start;
  width: 100%;
  padding-bottom: var(--spacing-2);
  cursor: pointer;
  // border-bottom: 1px solid var(--border-gray-normal);
  // &:hover .edit-btn {
  //   opacity: 1;
  // }
  @media (max-width: 1380px) {
    grid-template-columns: 2fr 1fr 1fr 0.6fr 0.6fr;
  }
  @media (min-width: 1256px) and (max-width: 1300px) {
    grid-template-columns: 2.2fr 1fr 1fr 0.8fr 0.8fr;
  }
  @media (min-width: 1181px) and (max-width: 1255px) {
    grid-template-columns: 2.4fr 1fr 1fr 0.8fr 0.8fr;
  }
  @media (min-width: 1110px) and (max-width: 1180px) {
    grid-template-columns: 2.2fr 1fr 1fr 0.8fr 0.8fr;
  }
  @media (min-width: 1025px) and (max-width: 1109px) {
    grid-template-columns: 2.6fr 1fr 1fr 0.8fr 0.8fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 2.7fr 1fr 1fr 0.9fr 0.9fr;
  }
}

.col-1 {
  display: flex;
  gap: var(--spacing-7);

  & > :first-child {
    visibility: hidden;
  }
}

.col-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--spacing-3);
  .select-dropdown .ant-select-selector {
    padding: var(--spacing-1) !important;
    border: none !important;
  }
  .ant-select .ant-select-arrow {
    color: var(--bg-gray-strongest);
    height: var(--spacing-2);
    top: 60%;
    right: 40%;
    svg {
      height: var(--spacing-2);
    }
  }
  .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
  }
  &-volume {
    width: fit-content;
    margin-bottom: var(--spacing-1);
    p {
      padding-bottom: 0 !important;
      @include textM(var(--text-gray-stronger), 400);
    }
  }

  &-text {
    display: flex;
    flex-direction: column;
    gap: 3px;
    &-btn {
      gap: 4px !important;
    }
    .model-status-text {
      font-size: 14px;
    }

    &-filename {
      white-space: normal;
      overflow: hidden;
      word-wrap: break-word;
      max-width: 202px;
      text-overflow: ellipsis;
      @include textM(var(--text-gray-strongest), 600);
      margin-bottom: var(--spacing-1);
      @media (min-width: 1431px) and (max-width: 1600px) {
        max-width: 160px;
      }

      @media (min-width: 1025px) and (max-width: 1430px) {
        max-width: 130px;
      }

      @media (max-width: 1024px) {
        max-width: 120px;
      }
    }
    p {
      @include textM(var(--text-gray-stronger), 400);
      padding-bottom: var(--spacing-2);
    }
  }
  &-size {
    width: fit-content;
    display: flex;
    margin-bottom: var(--spacing-1);
    align-items: center;
    p {
      padding: 0 !important;
      @include textM(var(--text-gray-normal), 400);
    }
  }
  &-select {
    width: 100%;
    align-self: center;
    flex: 1;
    .select-wrapper {
      border: none;
      .ant-select-selection-item {
        @include textS(var(--text-gray-normal), 400);
      }
      .suffix-icon-wrapper {
        svg {
          fill: var(--bg-brand-solid-normal);
        }
      }
    }
  }
}

.col-3 {
  p {
    white-space: nowrap;
    overflow: hidden;
    max-width: 260px;
    text-overflow: ellipsis;
    padding-bottom: var(--spacing-1);
    @include textM(var(--text-gray-stronger), 400);
  }
  @media (min-width: 1350px) and (max-width: 1600px) {
    p {
      max-width: 200px;
    }
    p:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      max-width: 200px;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 1267px) and (max-width: 1349px) {
    p {
      max-width: 150px;
    }
    p:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      max-width: 150px;
      text-overflow: ellipsis;
    }
  }
  @media (min-width: 1025px) and (max-width: 1266px), (max-width: 1024px) {
    p {
      max-width: 121px;
    }
    p:nth-child(1) {
      white-space: nowrap;
      overflow: hidden;
      max-width: 121px;
      text-overflow: ellipsis;
    }
  }
}

.col-4 {
  @include textM(var(--text-gray-stronger), 400);
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  p,
  div {
    white-space: nowrap;
    overflow: hidden;
    max-width: 180px;
    text-overflow: ellipsis;

    @media (min-width: 1301px) and (max-width: 1400px) {
      max-width: 160px;
    }
    @media (min-width: 1101px) and (max-width: 1300px) {
      max-width: 130px;
    }
    @media (min-width: 1024px) and (max-width: 1100px) {
      max-width: 120px;
    }
  }
}

.col-5 {
  margin-left: -20px;
  margin-right: 20px;
  .number-field {
    background: transparent;
  }
}

.col-6 {
  align-items: flex-end;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: end;
  &-text {
    margin-bottom: 35px;
      p:nth-child(1) {
        @include fontSetup(var(--text-gray-strongest), 20px, 30px, normal, 700);
        padding-bottom: var(--spacing-1);
      }
      p:nth-child(2) {
        @include fontSetup(var(--text-gray-normal), 12px, 16px, normal, 400);
      }
  }
  &-action {
    .edit-btn {
      visibility: hidden;
      opacity: 0;
      width: 78px;
    }
    .icon-loading {
      visibility: visible;
      opacity: 1;
    }
  }
  // &-action {
  //   display: flex;
  //   gap: 50px;
  //   align-items: center;
  //   .edit-btn {
  //     opacity: 0;
  //   }
  //   button:nth-child(2) {
  //     padding: var(--spacing-1) !important;
  //   }
  // }
}
.product-viewer {
  cursor: pointer;
}

.icon-loading img {
  animation: App-logo-spin 1s linear infinite reverse;
}

.checkbox-checked {
  visibility: visible;
}
.uneditable:hover {
  cursor: default;
}
