/* You can add global styles to this file, and also import other style files */
:root {
  /* global color */
  --gray-50: #fdfdfd;
  --gray-100: #f7f7f9;
  --gray-200: #edeef1;
  --gray-300: #dfe0e6;
  --gray-400: #cdcfd8;
  --gray-500: #b7bac6;
  --gray-600: #9da0b2;
  --gray-700: #7e849a;
  --gray-800: #434756;
  --gray-900: #22232b;
  --gray-950: #16181d;

  --brand-50: #fffcfc;
  --brand-100: #fef2f1;
  --brand-200: #fde3e0;
  --brand-300: #fbcdc8;
  --brand-400: #f9b0aa;
  --brand-500: #f68e84;
  --brand-600: #f26558;
  --brand-700: #ee3424;
  --brand-800: #8c150b;
  --brand-900: #470b05;
  --brand-950: #2f0704;

  --danger-50: #fffbfb;
  --danger-100: #ffefef;
  --danger-200: #ffdbdb;
  --danger-300: #ffbfbf;
  --danger-400: #ff9b9b;
  --danger-500: #ff7070;
  --danger-600: #ff3c3c;
  --danger-700: #ff0000;
  --danger-800: #8e0000;
  --danger-900: #4a0000;
  --danger-950: #330000;

  --warning-50: #fffefc;
  --warning-100: #fffbf2;
  --warning-200: #fff7e2;
  --warning-300: #fef1cc;
  --warning-400: #fee9af;
  --warning-500: #fddf8b;
  --warning-600: #fdd361;
  --warning-700: #fc9d31;
  --warning-800: #a07602;
  --warning-900: #4e3901;
  --warning-950: #322501;

  --success-50: #fcfefd;
  --success-100: #E9FFF2;
  --success-200: #e1f8eb;
  --success-300: #caf3db;
  --success-400: #acecc7;
  --success-500: #88e4ae;
  --success-600: #5dda91;
  --success-700: #00a344;
  --success-800: #1a723e;
  --success-900: #0e3c21;
  --success-950: #092a17;

  --info-50: #fcfeff;
  --info-100: #f3fbff;
  --info-200: #e4f6ff;
  --info-300: #d0eeff;
  --info-400: #b5e5ff;
  --info-500: #95daff;
  --info-600: #6fccff;
  --info-700: #42bdff;
  --info-800: #006fab;
  --info-900: #003551;
  --info-950: #002133;

  /* background color */
  --bg-base-base: #ffffff;
  --bg-base-bg: #fafbfc;
  --bg-base-bg-2: #f6f6f6;
  --bg-base-gray: #eeeff2;
  --bg-neutral-weaker: #85888e;
  --bg-neutral-normal: #61646C;

  --bg-gray-weaker: var(--gray-200);
  --bg-gray-weak: var(--gray-400);
  --bg-gray-normal: var(--gray-600);
  --bg-gray-stronger: var(--gray-700);
  --bg-gray-strongest: var(--gray-800);

  --bg-brand-solid-weaker: var(--brand-600);
  --bg-brand-solid-normal: var(--brand-700);
  --bg-brand-solid-stronger: var(--brand-800);

  --bg-brand-tonal-weaker: var(--brand-100);
  --bg-brand-tonal-normal: var(--brand-200);
  --bg-brand-tonal-stronger: var(--brand-300);

  --bg-danger-solid-weaker: var(--danger-600);
  --bg-danger-solid-normal: var(--danger-700);
  --bg-danger-solid-stronger: var(--danger-800);

  --bg-danger-tonal-weaker: var(--danger-100);
  --bg-danger-tonal-normal: var(--danger-200);
  --bg-danger-tonal-stronger: var(--danger-300);

  --bg-warning-solid-weaker: var(--warning-600);
  --bg-warning-solid-normal: var(--warning-700);
  --bg-warning-solid-stronger: var(--warning-800);

  --bg-warning-tonal-weaker: var(--warning-100);
  --bg-warning-tonal-normal: var(--warning-200);
  --bg-warning-tonal-stronger: var(--warning-300);

  --bg-success-solid-weaker: var(--success-600);
  --bg-success-solid-normal: var(--success-700);
  --bg-success-solid-stronger: var(--success-800);

  --bg-success-tonal-weaker: var(--success-100);
  --bg-success-tonal-normal: var(--success-200);
  --bg-success-tonal-stronger: var(--success-300);

  --bg-info-solid-weaker: var(--info-600);
  --bg-info-solid-normal: var(--info-700);
  --bg-info-solid-stronger: var(--info-800);

  --bg-info-tonal-weaker: var(--info-100);
  --bg-info-tonal-normal: var(--info-200);
  --bg-info-tonal-stronger: var(--info-300);

  /* text color */
  --text-base-brand: var(--brand-700);
  --text-base-danger: var(--danger-700);
  --text-base-warning: var(--warning-700);
  --text-base-info: var(--info-700);
  --text-base-success: var(--success-700);

  --text-gray-base: var(--bg-base-base);
  --text-gray-weaker: var(--gray-300);
  --text-gray-weak: var(--gray-500);
  --text-gray-normal: var(--gray-700);
  --text-gray-stronger: var(--gray-800);
  --text-gray-strongest: var(--gray-900);

  /* border color */
  --border-base-brand: var(--brand-700);
  --border-base-danger: var(--danger-700);
  --border-base-warning: var(--warning-700);
  --border-base-success: var(--success-700);
  --border-base-info: var(--info-700);

  --border-gray-weaker: var(--gray-50);
  --border-gray-normal: var(--bg-base-gray);
  --border-gray-strong: var(--gray-300);
  --border-gray-button: var(--gray-600);

  --border-normal: #eeeff2;
  /* global shadow */
  --drop-shadow-danger-focus-200: 0 0 0 2px #ffbbba;
  --drop-shadow-info-focus-2px-200: 0 0 0 2px var(--info-300);
  --drop-side-bar-left: 0 8px 15px 0 rgba(0, 0, 0, 0.05);

    --max-width: 1440px;

  /* spacing */
  --spacing-0: 0px;
  --spacing-0-5: 2px;
  --spacing-1: 4px;
  --spacing-1-5: 6px;
  --spacing-2: 8px;
  --spacing-2-5: 10px;
  --spacing-3: 12px;
  --spacing-3-5: 14px;
  --spacing-4: 16px;
  --spacing-5: 20px;
  --spacing-6: 24px;
  --spacing-7: 28px;
  --spacing-8: 32px;
  --spacing-9: 36px;
  --spacing-10: 40px;
  --spacing-12: 48px;
  --spacing-16: 64px;
  --spacing-20: 80px;
  --spacing-32: 128px;

  /* max width */
  --max-width: 1440px;
  --sidebar-max-width: 256px;

  /* opacity */
  --border-base-brand-opa-30: rgba(238, 52, 20, 0.3);
}
