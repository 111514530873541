@import "@/assets/scss/styles";

// .ant-popover {
//   &-content {
//     margin-top: 10px;
//   }
//   &-inner {
//     width: 177px !important;
//   }
// }

.viewer-modal {
  display: flex;
  gap: var(--spacing-8);
  &-wrapper {
    padding: 0 var(--spacing-2);
  }
  &-infor {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1.5;
    &-content {
      position: relative;
      padding-bottom: var(--spacing-3);
      padding-top: var(--spacing-9);
      border: 1px solid var(--border-gray-normal);
      &-text {
        padding-left: var(--spacing-4);
        &-units {
          padding-bottom: var(--spacing-1);
          display: flex;
          align-items: center;
          p:nth-child(1) {
            @include textM(var(--text-gray-normal),600);
            padding: 0 !important
          }
        }
        h3 {
          @include textL(var(--text-gray-stronger), 700);
          line-height: 28px !important;
          padding-bottom: var(--spacing-1);
        }
        p {
          span {
            @include textM(var(--text-gray-normal), 600);
          }
          @include textM(var(--text-gray-normal),400);
          padding-bottom: var(--spacing-1);
        }
        p:nth-child(4) {
          padding: var(--spacing-0);
        }
        .btn-resize {
          margin-top: var(--spacing-1);
          padding: var(--spacing-1-5) var(--spacing-0);
        }
      }
    }
  }
  &-model {
    flex: 3;
    background-color: var(--bg-base-gray);
    &-loading {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      .loading {
        padding-top: 0 !important;
        img {
          width: 50px;
        }
      }
    }
  }
  &-analysis {
    min-height: 480px;
    p:nth-child(1) {
      @include textL(var(--text-gray-stronger),600);
      padding-bottom: var(--spacing-2);
      padding-top: var(--spacing-3);
    }
    &-heading {
      display: flex;
      gap: var(--spacing-2);
      align-items: center;
      svg {
        width: 26px;
        height: 26px;
      }
      &-title {
        h4 {
          @include textL(var(--text-gray-stronger), 600);
        }
        p {
          @include textM(var(--text-base-success), 400);
        }
        &-warning {
          p {
            @include textM(var(--text-base-warning), 400);
          }
        }
        &-error {
          p {
            @include textM(var(--text-base-danger), 400);
          }
        }
      }
    }
    .ant-collapse-header {
      align-items: center !important;
      padding: 0 !important;
    }
    .ant-collapse-content-box {
      padding: 0 !important;
    }
  }
}
