@import "@/assets/scss/styles";

.top-toolbar {
  position: sticky;
  bottom: 5%;
  z-index: 2;

  &-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: var(--spacing-3-5) 0;
    z-index: 1000;
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 16px;
    .top-toolbar-button {
      &-edit, &-delete {
        padding: var(--spacing-1-5) var(--spacing-7);
        &:disabled {
          pointer-events: none;
        }
      }
    }
  }

  &-help {
    display: flex;
    align-items: center;
    gap: 26px;
    &-text {
      @include fontSetup(var(--text-gray-stronger), 16px, 20px, normal, 600);

      .top-toolbar-help-link {
        margin-left: 4px;
        color: var(--text-base-brand);
        cursor: pointer;
      }
    }
  }
}
