@import "@/assets/scss/styles";
.info-form-box {
  background-color: var(--bg-base-base);
  padding: var(--spacing-4);
  margin-top: var(--spacing-8);
  border: 1px solid var(--border-gray-normal);
}

.password-form-box {
  background-color: var(--bg-base-base);
  padding: var(--spacing-4);
  border: 1px solid var(--border-gray-normal);
}

.text-title {
  @include textXL(var(--text-gray-stronger), 600);
  font-family: "Open Sans", sans-serif;
  padding-bottom: var(--spacing-8);
}
