@import "@/assets/scss/variables";
.number-field {
  background-color: white;
  border: 1px solid var(--border-gray-normal);
  padding: var(--spacing-2);
  text-align: center !important;
  border-radius: var(--spacing-0-5);
  input,
  input:focus-visible {
    border: none;
    background: transparent;
    border-color: transparent;
    width: 100%;
    outline: none;
    text-align: center;
  }
  button {
    padding: 10px !important;
  }
}

.input-disabled {
  cursor: default;
}
