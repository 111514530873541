@import "@/assets/scss/styles";

.checkout {
  &-container {
    max-width: var(--max-width);
    box-sizing: border-box;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-6);
    padding: var(--spacing-8) var(--spacing-6);

    .ant-collapse-expand-icon {
      padding-inline-start: 6px !important;
    }
  }

  &-left {
    flex: 3;
  }

  &-right {
    flex: 1;
    max-width: 272px;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);

    &-footer {
      display: flex;
      gap: var(--spacing-2);
      .checkout-question {
        @include textL(var(--text-gray-stronger), 600);
        text-align: center;
        padding: var(--spacing-1-5) var(--spacing-4);
        a {
          cursor: pointer;
          color: var(--bg-brand-solid-normal) !important;
        }
      }
    }
  }
  &-review {
    .ant-collapse {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-3);
    }
    .ant-collapse-header {
      padding: var(--spacing-3) !important;
      border-radius: var(--spacing-1-5) !important;
      border-bottom: 1px solid var(--border-gray-normal);
    }

    .ant-collapse-content-box {
      padding: var(--spacing-0) !important;
    }
    .ant-collapse-item {
      background-color: var(--bg-base-base);
      border-radius: 6px;
      border: 1px solid var(--border-gray-normal);
      padding: var(--spacing-0);
    }
  }
}
.collapse-footer {
  display: flex;
  justify-content: end;
  gap: var(--spacing-2);
  padding: var(--spacing-3);
  border-top: 1px solid var(--border-gray-normal);
  button {
    padding: var(--spacing-1) var(--spacing-2) !important;
  }
}
