@import "@/assets/scss/styles.scss";

.custom-pagination {
  &-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    .page-size-selector {
      width: 113px;
    }

    .page-size-selector {
      .ant-select-selector {
        background-color: var(--bg-base-bg-2);
      }
    }

    .ant-select {
      width: 100px;
      .ant-select-selection-placeholder {
         @include textM(var(--text-gray-normal),400)
      }
    }
    .pagination-item {
      height: 34px;
      width: 34px;
      background-color: white;
      border: 1px solid var(--border-gray-strong);
      border-radius: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      a {
        font-size: 14px;
        color: var(--text-gray-normal);
        line-height: var(--spacing-5);
      }
      &-ellipsis {
        width: 100%;
        left: -10px;
      }
      &-link {
        height: 36px;
        width: 36px;
        background-color: white;
        border: 1px solid var(--border-gray-strong);
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &:hover {
        background-color: transparent;
      }
      &-disabled {
        cursor: not-allowed;
        a {
          cursor: not-allowed;

          color: rgba(0, 0, 0, 0.25);
          span {
            cursor: not-allowed;
          }
        }
      }

      &-active {
        border-color: var(--border-base-brand);
        border-radius: 0;

        a {
          color: var(--text-base-brand);
        }
      }
    }

    .ant-pagination {
      flex: 2;
      text-align: center;
      &-jump-next {
        height: 36px;
        width: 36px;
        background-color: white;
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &-prev,
      &-jump-next,
      &-jump-prev,
      &-next {
        margin: 0;
      }
      .ant-pagination-item {
        height: 36px;
        margin: 0;
        width: 36px;
        background-color: white;
        border: 1px solid var(--border-gray-strong);
        border-radius: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          font-size: 14px;
          color: var(--text-gray-normal);
          line-height: var(--spacing-5);
        }
        &-ellipsis {
          width: 100%;
          left: -10px;
        }
        &-link-icon {
          color: var(--text-base-brand) !important;
        }
        &-container {
          width: 100%;
        }
        &-link {
          height: 36px;
          width: 36px;
          background-color: white;
          border: 1px solid var(--border-gray-strong);
          border-radius: 0;
          color: var(--text-gray-normal);
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-active {
          border-color: var(--border-base-brand);
          border-radius: 0;

          a {
            color: var(--text-base-brand);
          }
        }
      }
    }
    .select-wrapper {
      position: relative;
      .suffix-icon-wrapper {
        right: 0;
        top: 0;
        position: absolute;
        z-index: 1;
        width: 3rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          fill: var(--bg-gray-normal);
          height: 11px;
        }
      }
    }
    .ant-select-focused .ant-select-selector {
      border-color: var(--border-base-brand) !important;
      box-shadow: var(--drop-shadow-danger-focus-200) !important;
    }

    .pagination-select {
      height: 36px;
      width: 100%;
      &-right {
        margin-left: var(--spacing-1);
        width: 90px;
      }
      .ant-select-selector {
        border-radius: 0;
        padding-inline: 10px !important;
        border: 1px solid #d9d9d9;
        padding: var(--spacing-2) var(--spacing-4) !important;
        outline: none !important;
        &:hover {
          border-color: var(--border-base-brand) !important;
        }
      }

      & .ant-select-selection-item {
        @include textL(var(--text-gray-normal), 400);
        padding-inline-end: 0 !important;
      }

      & .ant-select-selection-placeholder {
        padding-inline-end: 0 !important;
      }

      .placeholder-text {
        @include textL(var(--text-gray-normal), 500);
      }
    }

    .page-label {
      flex: 1;
      text-align: right;
    }
  }
  &-right {
    display: flex;
  }
}
.dropdown-menu-pagination {
  border-radius: 0 !important;
  .ant-select-item {
    border-radius: 0 !important;
    padding: 10px var(--spacing-3) !important;
  }

  &.ant-select-dropdown .ant-select-item-option-selected {
    background-color: var(--bg-brand-tonal-weaker) !important;
  }

  &.ant-select-dropdown .ant-select-item-option-active {
    background-color: var(--bg-brand-tonal-weaker) !important;
  }

  .ant-select-item-option-state {
    & .anticon-check {
      color: var(--bg-gray-strongest) !important;
    }
  }

  .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
    outline: none !important;
    border-radius: var(--spacing-0-5);
    box-shadow: none !important;
  }

  &.multiple {
    .ant-select-item-option-state {
      display: none !important;
    }
  }

  .clear-button-text {
    @include textS(var(--text-gray-stronger), 500);
  }

  .count-number-text {
    @include textS(var(--text-gray-normal), 400);
  }

  .item-text {
    @include textM(var(--text-gray-stronger), 500);
    margin-left: var(--spacing-2);
  }

  .sub-item-text {
    @include textS(var(--text-gray-normal), 400);
  }

  .add-btn-text {
    @include textM(var(--text-base-brand), 600);
  }

  .not-found-text {
    @include textM(var(--text-gray-normal), 400, italic);
    padding: var(--spacing-3) 0;
  }

  .ant-btn-text.ant-btn-dangerous {
    color: var(--text-base-brand) !important;
  }

  .footer-dropdown {
    border-top: 1px solid var(--border-gray-strong) !important;
  }

  .ant-btn-text {
    &:hover {
      background: none !important;
    }
  }

  .custom-placeholder .anticon {
    margin-right: var(--spacing-2);
  }

  .placeholder-search-text {
    @include textM(var(--text-gray-normal), 400);
  }
}
