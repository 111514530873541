@import '@/assets/scss/styles';
.shipping-method-box-collect {
    width: 416px;
    .title-method {
        @include textM(var(--text-gray-normal),700);
        padding-bottom: var(--spacing-3);
    }
    &-item{
      display: grid;
      grid-template-columns: var(--spacing-3) 120px 232px;
      justify-content: space-between;
      align-items: center;
      margin-bottom: var(--spacing-2);
      border: 1px solid var(--border-gray-normal);
      padding: var(--spacing-2) var(--spacing-3);
      .img-box {
        img {
            width: 26px;
        }
        display: flex;
        gap: var(--spacing-3);
        align-items: center;
        &-name {
            @include textM(var(--text-gray-stronger),600);
        }
      }
      .time-text {
        @include textM(var(--text-gray-normal),400);
        justify-self: self-end
      }
      .price-text {
        @include textM(var(--text-gray-strongest),600)
      }
    }
    .text-label-medium {
        @include textM(var(--text-gray-normal),700);
        font-family: Open Sans, sans-serif !important;
    }
    .btn-save-account-number {
      padding-top: var(--spacing-2);
      display: flex;
      gap: var(--spacing-2);
    }
}