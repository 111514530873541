@import "@/assets/scss/styles";
.model-item {
  .col {
    padding: var(--spacing-10) var(--spacing-4) var(--spacing-4)
      var(--spacing-4);
    background-color: white;
    margin-top: var(--spacing-4);
    position: relative;

    &:hover {
      box-shadow: var(--drop-side-bar-left);
      border: 1px solid var(--border-gray-strong);
      cursor: default;
    }
  }
  .skeleton-container {
    display: grid;
    grid-template-columns: 2.9fr 1.2fr 0.6fr 0.6fr;
    column-gap: 16px;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    padding-bottom: var(--spacing-2);
    &:hover .edit-btn {
      opacity: 1;
      cursor: default;
    }
    @media (max-width: 1380px) {
      grid-template-columns: 2.9fr 0.8fr 0.6fr 0.6fr;
    }
    @media (min-width: 1025px) and (max-width: 1266px) {
      grid-template-columns: 2.7fr 0.9fr 0.7fr 0.6fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 2.8fr 0.7fr 0.6fr 0.6fr;
    }
  }

  .col-1 {
    display: flex;
    gap: var(--spacing-10);
    &-part {
      display: flex;
      flex: 1;
      flex-direction: column;
      gap: var(--spacing-1);
    }
  }

  .col-4 {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
    margin-right: 20px;
  }

  .col-5 {
    .number-field {
      background: transparent;
    }
  }

  .col-6 {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: end;
    &-text {
      margin-bottom: 35px;
      p:nth-child(1) {
        @include fontSetup(var(--text-gray-strongest), 22px, 36px, normal, 700);
        padding-bottom: var(--spacing-1);
      }
      p:nth-child(2) {
        @include fontSetup(var(--text-gray-normal), 14px, 16px, normal, 400);
      }
    }
    &-action {
      display: flex;
      gap: 50px;
      align-items: center;
      .edit-btn {
        opacity: 0;
      }
      button:nth-child(2) {
        padding: var(--spacing-1) !important;
      }
    }
  }

  .product-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.product-tag {
  position: absolute;
  top: 0;
  cursor: default;
  left: 0;
  p {
    display: flex;
    gap: var(--spacing-1);
    align-items: center;
    z-index: 1;
    position: relative;
    padding: 6px var(--spacing-3);
    @include textM(var(--text-gray-stronger), 600);
  }
  span {
    @include textM(var(--text-gray-stronger), 600);
  }
}
