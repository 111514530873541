@import '@/assets/scss/styles';

.pick-up-box {
    border: 1px solid var(--border-gray-normal);
    padding: var(--spacing-6);
    width: 416px;
    .img-box {
        padding-bottom: var(--spacing-2);
    }
    p:nth-child(2) {
        @include textL(var(--text-gray-stronger),600);
    }
    p {
        padding-bottom: var(--spacing-1);
    }
}

.title-pick-up {
    padding-bottom: var(--spacing-2);
    @include textM(var(--text-gray-normal),700)
}