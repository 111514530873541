@import "@/assets/scss/styles";

.sticky-container {
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top: 5px;
}

.toolbar {
  &-container {
    @include textM(var(--text-gray-normal), 700);
    padding: var(--spacing-2) var(--spacing-4);
    border: 1px solid var(--border-gray-normal);
    background-color: white;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
    column-gap: 16px;
    justify-content: space-between;
    text-transform: uppercase;
    align-items: center;
    div:last-child {
      text-align: end;
    }
    .text-check-box {
      @include textM(var(--text-gray-stronger), 600);
    }

    @media (max-width: 1380px) {
      grid-template-columns: 2fr 1fr 1fr 0.6fr 0.6fr;
    }
    @media (min-width: 1256px) and (max-width: 1300px) {
      grid-template-columns: 2.2fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (min-width: 1181px) and (max-width: 1255px) {
      grid-template-columns: 2.4fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (min-width: 1110px) and (max-width: 1180px) {
      grid-template-columns: 2.2fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (min-width: 1025px) and (max-width: 1109px) {
      grid-template-columns: 2.6fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 2.7fr 1fr 1fr 0.9fr 0.9fr;
    }
  }
}

.toolbar-checkbox {
  display: flex;
  align-items: center;
  gap: var(--spacing-5);
}
