@import "@/assets/scss/styles.scss";

.auth-layout {
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
  &-content {
    overflow: auto;
    height: 100%;
    width: 100%;
    flex: 2;
  }
  &-sidebar {
    &-wrapper {
      flex: 1;
      background-image: url("../../assets/images/bg-auth.png");
      padding: 94px 52px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
      background-position-x: center;
      background-position-y: center;
      box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
    }
    &-top {
      color: var(--text-gray-base);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-6);
      margin-top: var(--spacing-6);
      h4 {
        margin-bottom: var(--spacing-4);
      }
      p {
        text-align: center;
      }
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-6);
      &-text {
        @include fontSetup(var(--text-gray-weak), var(--spacing-4), var(--spacing-6), normal, 600)
      }
      &-logos {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 52px;
        grid-row-gap: var(--spacing-6);
      }
    }
  }
}
