@import "@/assets/scss/mixins";
@import "@/assets/scss/variables";

.button {
  height: 100%;
  box-shadow: none;
}
// Primary Button Styles
.button-primary {
  background-color: var(--bg-brand-solid-normal);
  border: none;
  color: var(--text-gray-base) !important;
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-solid-weaker);
    color: var(--text-gray-base) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-solid-stronger);
    border-color: var(--bg-brand-solid-stronger);
    color: var(--text-gray-base) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-solid-normal);
    border-color: var(--border-base-brand);
    color: var(--text-gray-base) !important;
    box-shadow: var(--drop-shadow-danger-focus);
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-brand-solid-normal);
    border-color: var(--border-base-brand);
    color: var(--text-gray-base) !important;
    opacity: 0.3;
  }
}

.button-neutral {
  background-color: var(--bg-gray-stronger);
  border: none;
  color: var(--text-gray-base) !important;
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-normal);
    color: var(--text-gray-base) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-strongest);
    border-color: var(--border-base-brand);
    color: var(--text-gray-base) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-stronger);
    box-shadow: var(--drop-shadow-danger-focus-200);
    color: var(--text-gray-base) !important;
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-gray-stronger);
    color: var(--text-gray-base) !important;
    opacity: 0.3;
  }
}

// Button Hierarchy Styles for Primary
.button-primary.button-border-outline {
  background-color: transparent;
  border: 1px solid var(--border-base-brand);
  color: var(--text-base-brand) !important;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-weaker);
    border: 1px solid var(--border-base-brand);
    color: var(--text-base-brand) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-normal);
    border: 1px solid var(--border-base-brand);
    color: var(--text-base-brand) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-base);
    border: 1px solid var(--border-base-brand);
    color: var(--text-base-brand) !important;
    box-shadow: var(--drop-shadow-danger-focus);
  }

  &:disabled,
  .ant-btn-disabled {
    border: 1px solid var(--border-base-brand);
    color: var(--text-base-brand) !important;
    opacity: 0.3;
  }
}

.button-primary.button-border-tonal {
  background-color: var(--bg-brand-tonal-weaker);
  border: none;
  color: var(--text-base-brand) !important;
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-normal);
    color: var(--text-base-brand) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-stronger);
    color: var(--text-base-brand) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-weaker);
    color: var(--text-base-brand) !important;
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-brand-tonal-weaker);
    color: var(--text-base-brand) !important;
    opacity: 0.3;
  }
}

.button-primary.button-border-link {
  background-color: transparent;
  border: none;
  border-radius: var(--spacing-0-5);
  color: var(--text-base-brand) !important;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-weaker);
    color: var(--text-base-brand) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-brand-tonal-normal);
    color: var(--text-base-brand) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-base);
    color: var(--text-base-brand) !important;
    box-shadow: var(--drop-shadow-link-focus);
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: transparent;
    color: var(--text-base-brand) !important;
    opacity: 0.3;
  }
}

// Button Hierarchy Styles for Neutral
.button-neutral.button-border-outline {
  border: 1px solid var(--border-gray-strong);
  background-color: transparent;
  color: var(--text-gray-stronger) !important;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-gray);
    border: 1px solid var(--border-gray-strong);
    color: var(--text-gray-stronger) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-weaker);
    border: 1px solid var(--border-gray-strong);
    color: var(--text-gray-stronger) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-base);
    border: 1px solid var(--border-gray-strong);
    color: var(--text-gray-stronger) !important;
    box-shadow: var(--drop-shadow-danger-focus-200);
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-base-base);
    color: var(--text-gray-stronger) !important;
    opacity: 0.3;
  }
}

.button-neutral.button-border-tonal {
  border: none;
  background-color: var(--bg-base-gray);
  color: var(--text-gray-stronger) !important;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-weaker);
    color: var(--text-gray-stronger) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-weak);
    color: var(--text-gray-stronger) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-gray);
    color: var(--text-gray-stronger) !important;
    box-shadow: var(--drop-shadow-danger-focus-200);
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-base-gray);
    color: var(--text-gray-stronger) !important;
    opacity: 0.3;
  }
}

.button-neutral.button-border-link {
  background-color: transparent;
  border: 1px solid transparent;
  color: var(--text-gray-stronger) !important;

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-gray);
    border: 1px solid var(--border-gray-strong);
    color: var(--text-gray-stronger) !important;
  }

  &:active:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-gray-weaker);
    border: 1px solid var(--border-gray-strong);
    color: var(--text-gray-stronger) !important;
  }

  &:focus:not(:disabled):not(.ant-btn-disabled) {
    background-color: var(--bg-base-base);
    color: var(--text-gray-stronger) !important;
    border: 1px solid var(--border-gray-strong);
    box-shadow: var(--drop-shadow-danger-focus-200);
  }

  &:disabled,
  .ant-btn-disabled {
    background-color: transparent;
    color: var(--text-gray-stronger) !important;
    border: none;
    opacity: 0.3;
  }
}

.button-primary.button-size-extra-small {
  height: 100%;
  @include textS("", 600);
  padding: var(--spacing-1) var(--spacing-2);
}

.button-primary.button-size-small {
  @include textM("", 600);
  height: 100%;
  padding: var(--spacing-1) var(--spacing-3);
}

.button-primary.button-size-medium {
  @include textM("", 600);
  padding: 6px var(--spacing-4);
}

.button-primary.button-size-large {
  @include textM("", 600);
  padding: var(--spacing-2) var(--spacing-4);
}

.button-primary.button-size-extra-large {
  @include textL("", 600);
  padding: var(--spacing-3) 19px;
}

.button-neutral.button-size-extra-small {
  @include textS("", 600);
  padding: var(--spacing-1) var(--spacing-2);
}

.button-neutral.button-size-small {
  @include textM("", 600);
  padding: var(--spacing-1) var(--spacing-3);
}

.button-neutral.button-size-medium {
  @include textM("", 600);
  padding: 6px var(--spacing-4);
}

.button-neutral.button-size-large {
  @include textM("", 600);
  padding: var(--spacing-2) var(--spacing-4);
}

.button-neutral.button-size-extra-large {
  @include textL("", 600);
  padding: var(--spacing-3) 19px;
}
