@import "@/assets/scss/styles";
.order-review {
  &-test {
    padding: var(--spacing-4) var(--spacing-3);
    background-color: var(--bg-base-bg);
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
    column-gap: 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-gray-normal);
    p {
      @include textS(var(--text-gray-normal), 700);
    }
    p:nth-child(5) {
      justify-self: end;
    }

    @media (max-width: 1380px) {
      grid-template-columns: 1.5fr 1fr 1fr 0.6fr 0.6fr;
    }
    @media (min-width: 1267px) and (max-width: 1350px) {
      grid-template-columns: 1.7fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (min-width: 1025px) and (max-width: 1266px) {
      grid-template-columns: 2.25fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 2fr 1fr 1fr 0.9fr 0.9fr;
    }
  }
}
.order-note {
  padding: var(--spacing-6);
  &-label {
    display: flex;
    align-items: center;
    gap: var(--spacing-1);
    padding-bottom: var(--spacing-3);
    p:nth-child(2) {
      @include textM(var(--text-gray-normal), 700);
    }
  }

  .custom-notes {
    border: 1px solid transparent;
    transition: border-color 0.3s ease-in-out;
    border-radius: 0;
    padding: var(--spacing-0) var(--spacing-0) var(--spacing-1) var(--spacing-0);
  }

  .custom-notes.ant-input-outlined:focus {
    border-top-color: transparent !important;
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: var(--border-gray-normal) !important;
    outline: none;
    box-shadow: none;
  }

  p {
    @include textL(var(--text-gray-strongest), 400);
  }
  &-form {
    width: 100%;
  }
}
