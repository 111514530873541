@import "@/assets/scss/styles.scss";

.edit-specification-form {
  padding: var(--spacing-4);
  &-row {
    display: flex;
    gap: var(--spacing-4);
    .edit-specification-form-label {
      flex: 1;
      margin-bottom: var(--spacing-3) !important;
    }
  }
  &-post {
    display: flex;
    gap: var(--spacing-4);
    align-items: center;
    padding: var(--spacing-2) var(--spacing-3) var(--spacing-1) var(--spacing-3) !important;

    .custom-checkbox.medium .ant-checkbox-inner {
      margin-top: var(--spacing-1);
    }
    .number-field {
      border: none;
      flex: 2;
      button {
        padding: var(--spacing-1) !important;
      }
    }
    &-text {
      flex: 3;
      h5 {
        @include textM(var(--text-gray-strongest), 400);
        margin-bottom: var(--spacing-1);
      }
      p {
        @include textS(var(--text-gray-normal), 400);
      }
    }
  }
  &-quantity {
    padding: 6px var(--spacing-2);
    height: 36px !important;
    button {
      padding: 9px !important;
    }
    input {
      font-family: "Open Sans" !important;
    }
  }
  &-label {
    margin-bottom: var(--spacing-4);
    .placeholder-text {
      font-size: 14px !important;
    }

    label {
      @include textL(var(--text-gray-stronger), 400);
    }

    &-options {
      margin: var(--spacing-1) 0;

      label {
        margin-bottom: 7px;
        @include textM(var(--text-gray-stronger), 500);
      }
    }
  }

  &-action {
    &-btn {
      width: 100%;
    }
  }

  &-price,
  &-notes,
  &-tolerances,
  &-upload {
    margin: var(--spacing-4) 0;
  }

  &-upload {
    &-render-file {
      display: flex;
      align-items: center;
      gap: var(--spacing-2);
      width: fit-content;
      .icon-box {
        &-render {
          color: black;
          cursor: pointer;
          font-size: 10px;
          vertical-align: middle;
        }
        .loading-wrapper {
          display: flex;
          align-items: center;
          .loading {
            padding: 0;
          }
        }
      }
      p {
        @include textM(var(--text-gray-stronger), 400);
      }
    }
    &-text {
      padding-top: 16px;
      @include textS(var(--text-gray-normal), 400);
      font-style: italic !important;
    }
  }

  &-price {
    border-left: var(--spacing-0-5) solid var(--border-base-brand);
    .unit-price,
    .total-price {
      padding: var(--spacing-1) var(--spacing-2);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .unit-price {
      &-title {
        @include textM(var(--text-gray-stronger), 400);
      }
      &-value {
        @include textM(var(--text-gray-strongest), 600);
      }
    }

    .total-price {
      &-title {
        @include textM(var(--text-gray-stronger), 600);
      }
      &-value {
        @include textM(var(--text-gray-strongest), 700);
      }
    }
  }

  &-tolerances {
    &-title {
      @include textS(var(--text-gray-stronger), 500);
    }
    &-subtitle {
      margin-top: var(--spacing-1);
      @include textS(var(--text-gray-normal), 400);
      a {
        @include textS(var(--text-base-brand), 400);
      }
    }
  }
  &-material-dropdown {
    position: relative;
    a {
      position: absolute;
      top: var(--spacing-4);
      right: var(--spacing-10);
      @include textS(var(--text-base-brand), 700);

      &.disable {
        opacity: 0.6;
      }
    }
  }
  .select-dropdown .ant-select-selector {
    height: var(--spacing-12);
    padding: var(--spacing-3) var(--spacing-4) !important;
  }

  &-production-time {
    .select-dropdown .ant-select-selection-item {
      max-width: 140px;
    }
    .select-dropdown .ant-select-selector {
      height: 48px;
      padding: var(--spacing-3) var(--spacing-4) !important;
    }
  }
  .select-dropdown .ant-select-selection-item {
    @include textM(var(--text-gray-stronger), 400);
  }
  &-dropdown .item-text {
    font-weight: 400 !important;
  }
}

.post-price {
  display: flex;
  justify-content: end;
  flex: 1;
}

.post-label .text-label {
  margin-bottom: var(--spacing-2);
}

.dropdown-learn-more {
  margin-top: var(--spacing-1);
  @include textS(var(--text-gray-normal), 400);
  a {
    @include textS(var(--text-gray-normal), 400);
    text-decoration: underline;
  }
}
