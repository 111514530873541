@import "@/assets/scss/styles";

.custom-drawer {
  &-header {
    padding: var(--spacing-8) var(--spacing-4) 0 var(--spacing-4) !important;
    border-bottom: none !important;

    .ant-drawer-header-title {
      flex-direction: row-reverse;
      align-items: flex-start;
      padding-bottom: var(--spacing-4);
      border-bottom: 1px solid var(--border-normal);
    }
    h3 {
      @include textXL(var(--text-gray-strongest), 600);
    }
    h5 {
      @include textM(var(--text-gray-normal), 600);
    }
  }
  &-body {
    padding: var(--spacing-4) !important;
  }

  &-header {
    .drawer-header {
      display: flex;
      align-items: flex-start;
      gap: 18px;
    }
  }
}
