@import "@/assets/scss/styles.scss";

.manual-request {
  &-form {
    padding-bottom: var(--spacing-4);
  }
  &-hint-text {
    @include textS(var(--text-gray-normal), 400);
    font-style: italic;
    padding-bottom: var(--spacing-4) 0;
    span {
      text-wrap: nowrap;
    }
  }
}

.manual-request-form {
  &-label {
    margin-bottom: var(--spacing-4);
    .placeholder-text {
      font-size: 14px !important;
    }
    label {
      @include textS(var(--text-gray-normal), 600);
      margin-bottom: var(--spacing-2);
    }
    &-options {
      margin: var(--spacing-1) 0;
      label {
        margin-bottom: 7px;
        @include textM(var(--text-gray-stronger), 400);
      }
    }
  }
  &-select {
    display: flex;
    width: 100%;
    div {
      flex: 1;
      width: 100%;
    }
  }
  &-action {
    &-btn {
      width: 100%;
    }
  }
}
