@import "@/assets/scss/styles";

.heading-collapse {
  &-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  &-right {

    &-done {
      padding-top: 4px;
    }
    .loading {
      padding-top: 0px !important;
      width: 20px;
      height: 20px;  
    }
  }
  &-container {
    display: flex;
    gap: var(--spacing-2);
    align-items: start;
    img {
      padding: var(--spacing-0-5) 0;
    }
    h4 {
      @include textL(var(--text-gray-strongest), 600);
      margin-bottom: var(--spacing-1);
    }
    p {
      @include textM(var(--text-gray-normal), 400);
    }
  }
}
