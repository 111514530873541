@import "@/assets/scss/styles";

.quotes-detail-summary {
  &-po {
    background-color: var(--bg-base-base);
    padding: var(--spacing-4);
    margin: var(--spacing-2) 0;
    .item-name-file {
      width: 100px;
    }
  }
  &-box {
    background-color: white;
    padding: var(--spacing-0) var(--spacing-4) var(--spacing-4) var(--spacing-4);
    &-header {
      &-layout {
        padding: var(--spacing-6) var(--spacing-0);
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid var(--border-gray-normal);
        &-title {
          @include textXL(var(--text-gray-stronger), 600);
        }
      }
      &-status {
        background-color: var(--bg-warning-solid-normal);
        p {
          padding: var(--spacing-3) 0;
          text-align: center;
          @include textL(var(--bg-base-base), 600);
        }
        width: 80%;
        margin: 0 auto;
        &-quote {
          background-color: var(--bg-gray-weak);
          p {
            color: var(--text-gray-normal);
          }
        }
        &-paid {
          background-color: var(--bg-success-solid-normal);
        }
      }
      &-title {
        @include textXXL(var(--text-gray-stronger), 600);
        border-bottom: 1px solid var(--border-gray-normal);
        padding: var(--spacing-4) 0;
      }
    }
    &-content {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-3);
      padding: var(--spacing-4) 0;
      border-bottom: 1px solid var(--border-gray-normal);
      &-price {
        display: flex;
        justify-content: space-between;
        p:nth-child(1) {
          @include textM(var(--text-gray-normal), 400);
        }
        p:nth-child(2) {
          @include textM(var(--text-gray-strongest), 400);
        }
      }
    }
    &-total {
      padding-top: var(--spacing-4);
      &-price {
        display: flex;
        justify-content: space-between;
        p:nth-child(1) {
          @include textL(var(--text-gray-stronger), 400);
        }
        p:nth-child(2) {
          @include fontSetup(
            var(--text-gray-stronger),
            18px,
            28px,
            normal,
            700
          );
        }
      }
      &-btn {
        margin-top: var(--spacing-4);
        .btn-place-order {
          width: 100%;
        }
      }
      &-ask {
        padding-top: var(--spacing-4);
        p {
          @include textM(var(--text-gray-stronger), 600);
          text-align: center;
          font-family: "Open Sans", sans-serif !important;
          a {
            color: var(--bg-brand-solid-normal) !important;
          }
        }
      }
    }
  }
  &-footer {
    background-color: white;
    margin-top: var(--spacing-2);
    padding: var(--spacing-4);
    .invoice-btn {
      width: 100%;
      margin-bottom: var(--spacing-2);
    }
    p {
      padding: var(--spacing-2) var(--spacing-4);
      text-align: center;
      @include textL(var(--text-gray-stronger), 600);
      a {
        @include textL(var(--bg-brand-solid-normal), 600);
      }
    }
  }
}

.po-file-render {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background-color: var(--bg-gray-weaker);
  border-radius: 5px;
  padding: 0 10px;
  border-radius: 0.75rem;
  align-items: center;
  height: var(--spacing-6);
  img {
    cursor: pointer;
  }
  p {
    @include textM(var(--text-gray-stronger), 400);
    text-wrap: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
  }
}
