@import "@/assets/scss/styles";

.info-box {
  margin-bottom: var(--spacing-3);
  border: 1px solid var(--border-gray-normal);
  padding: var(--spacing-4);
  &-text-field {
    @include textM(var(--text-gray-strongest),600);
    padding-top: var(--spacing-3);
    padding-left: var(--spacing-3);
  }
}

.contact {
  p:nth-child(1) {
    padding-bottom: var(--spacing-2);
    @include textM(var(--text-gray-normal), 700);
    line-height: var(--spacing-6) !important;
  }
  p {
    @include textM(var(--text-gray-stronger), 400);
    padding-bottom: var(--spacing-1);
  }
}

.address {
  p:nth-child(1) {
    padding-top: var(--spacing-1);
    @include textM(var(--text-gray-normal), 700);
    line-height: var(--spacing-6) !important;
  }
  p {
    @include textM(var(--text-gray-stronger), 400);
    padding-bottom: var(--spacing-1);
  }
}

.title {
  @include textM(var(--text-gray-normal), 700);
  padding: var(--spacing-3) var(--spacing-3);

  &.delivery-address {
    padding-top: var(--spacing-0);
  }
}

.spacing-12 {
  margin-bottom: var(--spacing-3);
  padding: var(--spacing-0) var(--spacing-3);
}
