@import '@/assets/scss/styles';

.container-spec {
  padding: var(--spacing-6);
  max-width: var(--max-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.upload-box {
  background-color: white;
  padding: var(--spacing-4);
  margin-top: var(--spacing-4);
  margin-bottom: var(--spacing-12);
  border: 1px solid var(--border-gray-normal);

  .upload {
    height: 100%;
  }
}