@import "@/assets/scss/styles";
.model-item {
  .col {
    padding: var(--spacing-10) var(--spacing-4) var(--spacing-4)
      var(--spacing-4);
    background-color: white;
    margin-top: var(--spacing-2);
    position: relative;

    &:hover {
      box-shadow: var(--drop-side-bar-left);
      border: 1px solid var(--border-gray-strong);
      cursor: pointer;
    }
  }
  .corner-bottom-right-bevel {
    border-width: var(--spacing-8) var(--spacing-8) 0 0;
    border-style: solid;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 0;
    &-loading {
      border-color: var(--bg-gray-weaker) transparent transparent transparent;
    }
    &-warning {
      border-color: var(--bg-warning-tonal-weaker) transparent transparent
        transparent;
    }
    &-success {
      border-color: var(--bg-success-tonal-weaker) transparent transparent
        transparent;
    }
    &-error {
      border-color: var(--bg-danger-tonal-weaker) transparent transparent
        transparent;
    }
  }
  .product-tag {
    position: absolute;
    top: 0;
    cursor: default;
    left: 0;
    p {
      display: flex;
      gap: var(--spacing-1);
      align-items: center;
      z-index: 1;
      position: relative;
      padding: 6px var(--spacing-3);
      @include textS(var(--text-gray-stronger), 600);
    }
  }
  .container {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
    grid-column-gap: 16px;
    justify-content: space-between;
    align-items: start;
    width: 100%;
    padding-bottom: var(--spacing-2);
    border-bottom: 1px solid var(--border-gray-normal);
    cursor: pointer;
    &:hover .edit-btn {
      opacity: 1;
    }
    @media (max-width: 1380px) {
      grid-template-columns: 1.3fr 1fr 1fr 0.6fr 0.6fr;
    }
    @media (min-width: 1025px) and (max-width: 1266px) {
      grid-template-columns: 1fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 0.8fr 1fr 1fr 0.9fr 0.9fr;
    }
  }

  .col-1 {
    display: flex;
    gap: var(--spacing-7);
  }
  .col-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--spacing-3);
    .select-dropdown .ant-select-selector {
      padding: var(--spacing-1) !important;
      border: none !important;
    }
    .ant-select .ant-select-arrow {
      color: var(--bg-gray-strongest);
      height: var(--spacing-2);
      top: 60%;
      right: 40%;
      svg {
        height: var(--spacing-2);
      }
    }
    .ant-select-focused .ant-select-selector {
      box-shadow: none !important;
    }
    &-volume {
      width: fit-content;
      margin-bottom: var(--spacing-1);
      p {
        padding-bottom: 0 !important;
        @include textM(var(--text-gray-stronger), 400);
      }
    }
    &-size {
      width: fit-content;
      display: flex;
      margin-bottom: var(--spacing-1);
      align-items: center;
      p {
        padding: 0 !important;
      }
    }
    &-text {
      width: 100%;
      &-filename {
        white-space: nowrap;
        overflow: hidden;
        max-width: 202px;
        text-overflow: ellipsis;
        @include textM(var(--text-gray-strongest), 600);
        margin-bottom: var(--spacing-1);
      }
      p {
        @include textM(var(--text-gray-stronger), 400);
        padding-bottom: var(--spacing-2);
      }
      .button {
        min-width: 136px;
        background: var(--bg-base-bg-2);
        &-loading {
          span {
            color: var(--text-gray-stronger);
          }
        }

        &-success {
          span {
            color: var(--text-base-success);
          }
        }

        &-warning {
          span {
            color: var(--text-base-warning);
          }
        }

        &-error {
          span {
            color: var(--text-base-danger);
          }
        }
      }
    }
    &-select {
      width: 100%;
      align-self: center;
      flex: 1;
      .select-wrapper {
        border: none;
        .ant-select-selection-item {
          @include textS(var(--text-gray-normal), 400);
        }
        .suffix-icon-wrapper {
          svg {
            fill: var(--bg-brand-solid-normal);
          }
        }
      }
    }
  }

  .col-3 {
    p {
      // white-space: nowrap;
      // overflow: hidden;
      // max-width: 160px;
      // text-overflow: ellipsis;
      padding-bottom: var(--spacing-2);
      @include textM(var(--text-gray-stronger), 400);
    }
  }

  .col-4 {
    @include textM(var(--text-gray-stronger), 400);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-1);
  }

  .col-5 {
    .number-field {
      background: transparent;
    }
  }

  .col-6 {
    align-items: flex-end;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: end;
    &-text {
      margin-bottom: 35px;
      p:nth-child(1) {
        @include fontSetup(var(--text-gray-strongest), 22px, 36px, normal, 700);
        padding-bottom: var(--spacing-1);
      }
      p:nth-child(2) {
        @include fontSetup(var(--text-gray-normal), 14px, 16px, normal, 400);
      }
    }
    &-action {
      display: flex;
      gap: 50px;
      align-items: center;
      .edit-btn {
        opacity: 0;
      }
      button:nth-child(2) {
        padding: var(--spacing-1) !important;
      }
    }
  }

  .date-delivery {
    display: flex;
    justify-content: end;
    padding-top: var(--spacing-2);
    gap: var(--spacing-2);
    p:nth-child(1) {
      @include textM(var(--text-gray-normal), 500);
    }
    p:nth-child(2) {
      @include textM(#00a344, 600);
    }
  }
  .product-viewer {
    cursor: pointer;
  }

  .icon-loading img {
    animation: App-logo-spin 1s linear infinite reverse;
  }

  .error-message {
    padding: var(--spacing-2);
    @include textM(var(--text-base-danger), 500);
    flex: 2;
    span {
      @include textM(var(--text-base-danger), 700);
      text-decoration: underline;
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}
