@import '@/assets/scss/styles';

.custom-modal-warning {
    &-title {
        text-align: center;
        padding-bottom: var(--spacing-6);
        padding-top: var(--spacing-2);
        font-size: 16px; 
        font-weight: 400; 
        line-height: 24px; 
    }
    &-button {
        display: flex;
        justify-content: center;
        gap: var(--spacing-2);
        button {
            width: 120px !important;
        }
    }
}