@import "@/assets/scss/styles";

.order {
  &-production-time {
    display: flex;
    justify-content: end;
    gap: var(--spacing-2);
    border-top: 1px solid var(--border-gray-normal);
    padding: var(--spacing-2) var(--spacing-0) var(--spacing-4) var(--spacing-0);
    p:nth-child(1) {
      @include textL(var(--text-gray-normal), 400);
    }
    p:nth-child(2) {
      @include textL(var(--text-base-success), 700);
    }
  }
  &-checkout-container {
    padding: var(--spacing-0) var(--spacing-4);
  }
  &-review-box-test {
    grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
    column-gap: 16px;
    display: grid;
    justify-content: space-between;
    padding: var(--spacing-4) var(--spacing-0);
    border-bottom: 1px solid var(--border-gray-normal);
    @media (max-width: 1380px) {
      grid-template-columns: 1.5fr 1fr 1fr 0.6fr 0.6fr;
    }
    @media (min-width: 1267px) and (max-width: 1350px) {
      grid-template-columns: 1.7fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (min-width: 1025px) and (max-width: 1266px) {
      grid-template-columns: 2.25fr 1fr 1fr 0.8fr 0.8fr;
    }
    @media (max-width: 1024px) {
      grid-template-columns: 2fr 1fr 1fr 0.9fr 0.9fr;
    }

    &:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-drawings {
    margin: var(--spacing-2) 0;
    display: flex;
    cursor: default;
    align-items: center;
    flex-wrap: wrap;
    gap: var(--spacing-1);
    &-item {
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include textM(var(--text-gray-stronger), 500);
      }
      border-radius: 99999px;
      padding: 0 var(--spacing-2);
      align-items: center;
      background-color: var(--bg-gray-weaker);
      display: flex;
      max-width: 180px;
      @media (min-width: 1441px) and (max-width: 1600px) {
        max-width: 150px;
      }
      @media (min-width: 1025px) and (max-width: 1440px) {
        max-width: 96px;
      }
      @media (max-width: 1024px) {
        max-width: 76px;
      }
      span {
        cursor: pointer;
      }
    }
  }
  &-item-box {
    &-col-1 {
      display: flex;
      gap: var(--spacing-3);
      &-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 180px;
        @include textM(var(--text-gray-strongest), 600);
        @media (min-width: 1025px) and (max-width: 1440px) {
          max-width: 100px;
        }
        @media (max-width: 1024px) {
          max-width: 80px;
        }
      }
      &-size {
        width: fit-content;
        display: flex;
        align-items: center;
        margin: var(--spacing-1) 0;
        p {
          white-space: normal;
          word-wrap: break-word;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 180px;
          padding: 0 !important;
          @include textM(var(--text-gray-normal), 400);
        }
        @media (min-width: 1025px) and (max-width: 1440px) {
          p {
            max-width: 100px;
          }
        }
        @media (max-width: 1024px) {
          p {
            max-width: 80px;
          }
        }
      }
      &-volume {
        width: fit-content;
        p {
          padding-bottom: 0 !important;
          @include textXS(var(--text-gray-stronger), 400);
        }
      }
      .quote-drawings {
        margin: var(--spacing-2) 0;
        display: flex;
        cursor: default;
        align-items: center;
        flex-wrap: wrap;
        gap: var(--spacing-1);
        &-item {
          p {
            max-width: 193px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            @include textS(var(--text-gray-stronger), 500);
          }
          border-radius: 99999px;
          padding: 0 var(--spacing-2);
          align-items: center;
          background-color: var(--bg-gray-weaker);
          display: flex;
          span {
            cursor: pointer;
          }
        }
      }
      // @media (min-width: 1025px) and (max-width: 1440px) {
      //   &-title {
      //     max-width: 100px;
      //   }
      // }
      // @media (max-width: 1024px) {
      //   &-title {
      //     max-width: 80px;
      //   }
      // }
    }
    &-col-2 {
      max-width: 206px;
      @media (min-width: 1441px) and (max-width: 1700px) {
        max-width: 180px;
      }
      @media (min-width: 1025px) and (max-width: 1440px) {
        max-width: 100px;
      }
      @media (max-width: 1024px) {
        max-width: 80px;
      }
      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include textM(var(--text-gray-stronger), 400);
        padding-bottom: var(--spacing-1);
      }
    }
    &-col-3 {
      p {
        text-align: center;
        @include textM(var(--text-gray-stronger), 400);
      }
    }
    &-col-4 {
      text-align: right;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: space-between;
      p {
        @include textL(var(--text-gray-strongest), 700);
        padding-bottom: var(--spacing-2);
      }
      p:nth-child(2) {
        @include textS(var(--text-gray-normal), 400);
      }
      &-badge {
        position: absolute;
        width: max-content;
        bottom: 0;
        right: 0;

        p {
          @include textM(var(--success-700), 500);
          padding: 0;
        }
      }
    }
    &-col-5 {
      align-self: self-start;
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-bottom: var(--spacing-1);
        @include textS(var(--text-gray-stronger), 400);
      }
    }
  }
}
