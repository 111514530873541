@import "@/assets/scss/styles";

.order {
  max-width: calc(100% - var(--spacing-1));
  height: calc(100vh - 140px);

  &-container {
    max-width: var(--max-width);
    margin: 0 auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-10) var(--spacing-6);
    height: 100%;
  }

  &-status {
    text-align: end;
    @include textL(var(--text-gray-stronger), 600);

    &-success {
      text-align: end;
      @include textL(var(--text-base-success), 600);
    }
  }
}