@import "variables";
@import "mixins";
body {
  background-color: var(--bg-base-base);
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
}
a {
  text-decoration: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b5b5b5;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.header-text {
  @include textDisplayXS(var(--text-gray-stronger), 700);
}

.header-subtext {
  @include textXL(var(--text-gray-stronger), 400);
  margin-left: 15px;
}

.error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-1);
  &-text {
    @include textS(var(--text-base-danger), 400);
  }
  &-icon {
    color: var(--text-base-danger);
    margin-right: var(--spacing-1);
  }
}
