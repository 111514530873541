@import "@/assets/scss/styles.scss";

.layout {
  height: 100vh;
  display: flex;
  background-color: var(--bg-base-bg);
  &-content {
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }
  .heading {
    box-shadow: var(--drop-side-bar-left);
  }
  .content {
    height: calc(100% - 60px);
    width: 100%;
    overflow: auto;
  }
}
