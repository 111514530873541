@import "@/assets/scss/styles";

.new-quote-container {
  max-width: var(--max-width);
  margin: 0 auto;
  box-sizing: border-box;
}

.create-quote {
  padding: var(--spacing-6);

  .content-container {
    margin-bottom: var(--spacing-6);
    display: flex;

    .content-box {
      background-color: white;
      padding: var(--spacing-4);
      height: 100%;
      flex: 1;
      border: 1px solid var(--border-gray-normal);

      .custom-dragger {
        padding-top: 0 !important;
      }

      &.vertical {
        display: flex;
        flex-direction: column;
        gap: 14px;
      }

      .title {
        @include textXL(var(--text-gray-stronger), 600);
      }

      .subtitle-upload {
        @include textS(var(--text-gray-normal), 400);
        padding-top: var(--spacing-2);

        img {
          vertical-align: middle;
        }
      }

      .upload-footer {
        display: flex;
        align-items: center;
        gap: var(--spacing-1);
        p {
          @include textS(var(--text-gray-normal), 400);
        }
      }
    }

    .select-tech {
      margin: 0;

      .divider {
        margin: 0;
      }
    }
  }

  .manual-request-box {
    background-color: var(--bg-base-base);
    width: 431px;
    padding: var(--spacing-2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: var(--spacing-1);
    &-left {
      div:nth-child(1) {
        img {
          vertical-align: middle;
        }
      }

      .text-box {
        width: 310px;

        p:nth-child(1) {
          @include textL(var(--text-gray-stronger), 600);
          padding: var(--spacing-3) var(--spacing-0) var(--spacing-1)
            var(--spacing-0);
        }

        p:nth-child(2) {
          @include textM(var(--text-gray-normal), 400);
        }
      }
    }
  }

  .banner-abandoned-cart {
    background-color: var(--bg-base-base);
    padding: var(--spacing-4);
    p {
      border-left: 3px solid var(--bg-brand-solid-normal);

      span {
        padding-left: var(--spacing-4);
        @include textXL(var(--text-gray-stronger), 700);
      }
    }
  }
}
