@import "@/assets/scss/styles.scss";

.sidebar {
  &-wrapper {
    background-color: #2D2D2D;
    color: var(--text-base-brand);
    height: 100vh;
    max-width: var(--sidebar-max-width);
    transition: all 0.5s;

    &.sidebar-open {
      width: var(--sidebar-max-width);
    }

    &.sidebar-close {
      width: 90px;
    }
    @media (max-width: 1180px) { 
      &.sidebar-open {
        width: 90px;
      }
    }
  }

  &-container {
    width: 100%;
    padding: var(--spacing-12) var(--spacing-0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 90%;
  }

  &-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-4);
    width: 100%;
    box-sizing: border-box;
    padding: var(--spacing-0) var(--spacing-6);
  }

  &-item {
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 6px;
    cursor: pointer;
    gap: var(--spacing-2);

    &-toggle-sidebar {
      cursor: pointer;
      @media (max-width: 1180px) {
        display: none;
      }
    }

    &-container {
      width: 100%;
    }

    img {
      margin: var(--spacing-2) 0 var(--spacing-2) var(--spacing-2);
    }

    &-text {
      transition: all 0.5s;
      margin: 0;
      overflow: hidden;
      text-wrap: nowrap;
      @include textL(var(--text-gray-normal), 500);

      &.sidebar-open {
        flex: 1;
      }

      &.sidebar-close {
        flex: 0;
      }
      @media (max-width: 1180px) { 
        &.sidebar-open {
          flex: 0;
        }
      }
    }
  }

  &-bottom {
    display: flex;
    box-sizing: border-box;
    padding: var(--spacing-0) var(--spacing-6);
    flex-direction: column;
    width: 100%;
    border-top: 1px solid var(--brder-gray-strong);
    gap: var(--spacing-4);

    &-avatar {
      cursor: pointer;

      img {
        border-radius: 50%;
        border: none;
      }

      &-active {
        border-bottom: var(--spacing-0-5) solid var(--border-base-brand);

        img {
          border-radius: 50%;
        }

        p {
          @include textL(var(--text-gray-base), 600);
        }
      }
    }

    &-icon {
      cursor: pointer;
    }
  }

  &-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-10);
    width: 100%;
  }

  &-logo {
    transition: all 0.5s;
    cursor: pointer;

    &.sidebar-open {
      padding: var(--spacing-4) var(--spacing-0) var(--spacing-4);
      width: var(--spacing-16);
      height: var(--spacing-16);
    }

    &.sidebar-close {
      padding: var(--spacing-7) 1px;
      width: var(--spacing-10);
      height: var(--spacing-10);
    }

    @media (max-width: 1180px) { 
      &.sidebar-open {
        padding: var(--spacing-7) 1px;
        width: var(--spacing-10);
        height: var(--spacing-10);
      }
    }
  }

  &-item-active {
    border-bottom: var(--spacing-0-5) solid var(--border-base-brand);

    .sidebar-item-text {
      @include textL(var(--text-gray-base), 600);
    }
  }
}