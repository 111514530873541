@import '@/assets/scss/variables';

.ant-radio-wrapper {
  .ant-radio-inner {
    background-color: var(--bg-base-base);
    border-color: var(--border-gray-strong) !important;
  }

  .ant-radio:hover .ant-radio-inner {
    background-color: var(--bg-base-gray);
    border-color: var(--border-gray-strong);
  }

  .ant-radio-checked .ant-radio-inner {
    background-color: white;
    border-color: var(--bg-brand-solid-normal);
  }

  .ant-radio-checked:hover .ant-radio-inner {
    background-color: var(--bg-brand-solid-stronger);
    border-color: var(--bg-brand-solid-stronger);
  }

  &:focus .ant-radio-inner {
    background-color: var(--bg-base-base);
    border: var(--border-gray-strong);
    box-shadow: var(--drop-shadow-danger-focus-200);
  }

  .ant-radio-disabled .ant-radio-inner {
    background-color: var(--bg-base-gray);
    border-color: var(--border-gray-strong);
    opacity: 0.5;
  }
}

.radio-style {
  .ant-radio {
    &-checked .ant-radio-inner {
      background-color: var(--bg-brand-solid-normal);
      border-color: var(--bg-brand-solid-normal);
    }

    &-checked:hover .ant-radio-inner {
      background-color: var(--bg-brand-solid-stronger);
      border-color: var(--bg-brand-solid-stronger);
    }

    &-checked:focus .ant-radio-inner {
      background-color: var(--bg-brand-solid-normal);
      border-color: var(--bg-brand-solid-normal);
      box-shadow: var(--drop-shadow-danger-focus-200);
    }

    &-checked.ant-radio-disabled .ant-radio-inner {
      background-color: var(--bg-brand-solid-normal);
      border-color: var(--bg-brand-solid-normal);
      opacity: 0.5;

      &::after {
        background-color: var(--bg-base-base);
      }
    }

    &:hover:not(.ant-radio-checked) .ant-radio-inner {
      background-color: var(--bg-base-gray);
      border-color: var(--border-gray-strong);
    }
  }
}
