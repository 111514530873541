@import '@/assets/scss/styles';
.ant-form-vertical {
    width: 100%;
    .ant-form-item:not(.ant-form-item-horizontal) {
        .ant-form-item-label {
            label {
                @include textL(var(--text-gray-stronger),500)
            }
        }
    }
}

.text-forgot-password {
    text-align: right;
    a {
        @include textM(var(--text-base-brand),600);
        font-family: "Open Sans", sans-serif;
    }
}

.btn-login {
    width: 70%;
    margin-top: var(--spacing-10);
}

.icon-google {
    margin-right: var(--spacing-3);
}

.margin-bottom > * {
    margin-bottom: var(--spacing-3);
}

.text-checkbox {
    @include textM(var(--text-gray-stronger),400)
}