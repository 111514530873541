@import "@/assets/scss/styles";

.abandon-cart {
  max-height: 540px;
  overflow-y: auto;
  padding: 0 var(--spacing-4);
  &-container {
    padding: var(--spacing-4) 0;
    background-color: var(--bg-base-base);
  }
  &-banner {
    padding: 0 var(--spacing-4);
    padding-bottom: var(--spacing-6);
    p {
      border-left: 3px solid var(--bg-brand-solid-normal);
      span {
        padding-left: var(--spacing-4);
        @include textXL(var(--text-gray-stronger), 700);
      }
    }
  }
}

.loading-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--spacing-2) 0;
  .loading-wrapper {
    .loading {
      padding-top: 10px !important;
      img {
        width: 36px;
      }
    }
  }
}
