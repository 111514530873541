@import "@/assets/scss/styles";

.custom-modal {
  &-content {
    border-radius: var(--spacing-0-5) !important;
    padding: 0 !important;
  }
  &-header {
    padding: var(--spacing-4) !important;
    margin-bottom: 0 !important;
    border-bottom: 1px solid #dde0e4 !important;
    .ant-modal-title {
      @include textXXL(var(--text-gray-strongest), 700);
    }
  }
  &-body {
    padding: var(--spacing-4) !important;
  }
  &-footer {
    text-align: center;
    padding: var(--spacing-4) var(--spacing-0);

    .button + .button {
      margin-left: var(--spacing-3);
    }
  }
}
