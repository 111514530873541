@import "@/assets/scss/styles";

.dfm-check {
  &-container {
    h4 {
      @include textM(var(--text-gray-normal), 700);
    }
  }
  &-items {
    overflow-x: auto;
  }
  &-pre {
    border-left: 1px solid var(--border-gray-normal);
    border-right: 1px solid var(--border-gray-normal);
    &-title {
      padding-left: var(--spacing-4);
      @include textM(var(--text-gray-stronger), 600);
    }
    &-item {
      display: flex;
      gap: var(--spacing-2);
      padding: var(--spacing-2) var(--spacing-4);
      border-radius: 0.5;
      svg {
        width: var(--spacing-5);
        height: 29px;
      }
      &-text {
        h5 {
          @include fontSetup(
            var(--text-gray-strongest),
            14px,
            20px,
            normal,
            600
          );
          padding-bottom: var(--spacing-1);
        }
        p {
          @include fontSetup(var(--danger-700), 13px, 20px, normal, 400);
        }

        p.no-style {
          @include fontSetup(var(--text-gray-normal), 13px, 20px, normal, 400);
        }
      }
    }
  }
  &-other {
    border: 1px solid var(--border-gray-normal);
    padding: var(--spacing-4) 0;
    &-header {
      display: flex;
      padding-left: var(--spacing-4);
      gap: var(--spacing-2);
      align-items: start;
      padding-bottom: var(--spacing-2);
      padding-right: var(--spacing-4);

      p,
      a {
        @include fontSetup(var(--text-gray-stronger), 13px, 20px, normal, 400);
      }
    }
    &-content {
      &-detail {
        padding-left: 38px;
        &-text {
          padding-bottom: 12px;
          &-header {
            display: flex;
            gap: var(--spacing-1);
            h5 {
              @include fontSetup(
                var(--text-gray-stronger),
                14px,
                20px,
                normal,
                600
              );
              padding-bottom: var(--spacing-1);
            }
          }

          p {
            @include fontSetup(
              var(--text-gray-normal),
              13px,
              20px,
              normal,
              400
            );
          }
        }
      }
    }
  }
  &-warning {
    display: flex;
    padding-top: 76px;
    align-items: start;
    gap: var(--spacing-2);
    align-items: center;
    width: 384px;
    svg {
      width: var(--spacing-5);
      height: var(--spacing-5);
      color: var(--bg-gray-stronger);
    }
    @include textM(var(--text-gray-normal), 400);
  }
  .ant-collapse-arrow {
    width: var(--spacing-5);
    height: var(--spacing-5);
    padding: var(--spacing-1);
    color: var(--bg-gray-normal) !important;
  }
}

.custom-tooltip {
  @include textS(var(--text-gray-base), 400);
  backdrop-filter: blur(6px);
}

.text-navigate-link {
  text-decoration: underline;
  @include textS(var(--text-gray-stronger), 400);
  &:hover {
    color: var((--text-gray-stronger));
    text-decoration: underline;
  }
}
