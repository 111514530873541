@import "@/assets/scss/styles.scss";

.login {
  &-form {
    max-width: 480px;
    box-sizing: border-box;
    margin: 0 auto;
    height: 100%;
  }
  &-title {
    width: 100%;
    display: flex;
    justify-content: start;
    padding-bottom: var(--spacing-10);
    @include textDisplayS(var(--text-gray-strongest), bold);
  }
}
