@import '@/assets/scss/styles';

.billing-contact {
    &-title {
        @include textXL(var(--text-gray-stronger),700);
        padding-bottom: var(--spacing-2);
    }
}

.billing-address {
    &-title {
        @include textXL(var(--text-gray-stronger),700);
        padding-bottom: var(--spacing-2);
    }
    &-notice {
        @include textL(var(--text-gray-normal),400);
        padding-bottom: var(--spacing-2);
    }
}