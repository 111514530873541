@import '@/assets/scss/styles';

.upload-cad-file {
  & .content-box {
    background-color: white;
    height: 100%;
    flex: 1;

    &.vertical {
      display: flex;
      flex-direction: column;
      gap: 14px;
    }

    .custom-dragger {
      height: 20% !important;
      padding-bottom: 0 !important;
    }

    .upload-icon {
      padding: var(--spacing-1) !important;
      width: var(--spacing-4) !important;
      height: var(--spacing-4) !important;
    }

    .title {
      @include textL(var(--text-gray-stronger), 500)
    }

    .upload-hint {
      @include textM(var(--text-gray-weak), 400);
    }
  }
}