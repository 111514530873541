@import "@/assets/scss/styles.scss";

.minus-checkbox {
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: white;
  color: inherit; 
  cursor: pointer;
  width: var(--spacing-5) !important;
  height: var(--spacing-5) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  box-sizing: border-box;
  user-select: none;

  &.checked {
    border-color: var(--bg-brand-solid-normal) !important;
    background-color: var(--bg-brand-solid-normal) !important;
  }

  &:hover {
    box-shadow: var(--drop-shadow-danger-focus-200) !important;
    border-color: transparent !important;
  }

  &:active{
    background-color: var(--bg-brand-solid-stronger) !important;
    box-shadow: none !important;
  }
  
}