@import '@/assets/scss/styles';

.quote-page {
    max-width: calc(100% - var(--spacing-1));
    height: calc(100vh - 140px);
}
.quote {
    &-container {
        max-width: var(--max-width);
        margin: 0 auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: var(--spacing-10) var(--spacing-6);
    }
}

.quote-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--spacing-4);
    &-modal-text {
        @include textL(var(--text-gray-stronger),400);
    }
}

.mr-8 {
    margin-right: var(--spacing-2);
}