@import "@/assets/scss/styles";
.popover {
    min-width: 177px;
    &-box {
        border-bottom: 1px solid var(--border-gray-normal);
        &-select {
            &-item {
                padding-bottom: var(--spacing-4);
                p {
                    @include textM(var(--bg-neutral-normal),500);
                }
            }
        }
    }
    &-btn {
        padding-top: var(--spacing-2);
        display: flex;
        justify-content: flex-end;
    }
}