@import "@/assets/scss/styles";

.main-layout {
  width: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-3-5) var(--spacing-6);
    &-left {
      display: flex;
      align-items: center;
      gap: var(--spacing-2);
      .back-button {
        padding: var(--spacing-2) !important;
      }
    }
  }

  &-content {
    height: calc(100% - 60px);
  }
}