@import '@/assets/scss/styles';
.loading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;

    &-title {
        @include textXL(var(--text-gray-stronger),400);
    }

    &-fail-title {
        @include textDisplayS(var(--text-gray-stronger),400)
    }

    p {
        padding-top: var(--spacing-20);
        padding-bottom: 27px;
    }
}

