@mixin fontSetup($color, $fontSize, $lineHeight, $fontStyle, $fontWeight) {
  color: $color;
  font-size: $fontSize;
  line-height: $lineHeight;
  font-style: $fontStyle;
  font-weight: $fontWeight;
}

@mixin textDisplayXXL($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 72px, 90px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textDisplayXL($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 60px, 72px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textDisplayL($color, $fontWeight , $fontStyle: normal) {
  @include fontSetup($color, 48px, 60px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textDisplayM($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 36px, 44px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textDisplayS($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 30px, 38px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textDisplayXS($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 24px, 32px, $fontStyle, $fontWeight);
  letter-spacing: -2%;
}

@mixin textXXL($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 20px, 30px, $fontStyle, $fontWeight);
}

@mixin textXL($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 18px, 28px, $fontStyle, $fontWeight);
}

@mixin textL($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 16px, 24px, $fontStyle, $fontWeight);
}

@mixin textM($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 14px, 24px, $fontStyle, $fontWeight);
}

@mixin textS($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 12px, 16px, $fontStyle, $fontWeight);
}

@mixin textXS($color, $fontWeight, $fontStyle: normal) {
  @include fontSetup($color, 10px, 12px, $fontStyle, $fontWeight);
}