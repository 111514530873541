@import '@/assets/scss/styles';

.title-toast {
    @include textM(var(--text-gray-stronger),600);
    font-family: "Open Sans", sans-serif !important;
}

.content-text {
    @include textS(var(--text-gray-stronger),400);
    font-family: "Open Sans", sans-serif !important;
}

.Toastify__toast {
    padding: var(--spacing-3) var(--spacing-4);
    &-container {
        width: 400px;
    }
}
.toast-container {
    display: flex;
    align-items: flex-start;
}