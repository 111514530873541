@import "@/assets/scss/styles";

.pointer {
  cursor: pointer;
}

.status-quote-col {
  display: flex;
  justify-content: end;
}

.status-col{
    text-align: end;
}