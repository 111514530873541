@import "@/assets/scss/styles.scss";

.register {
  &-page {
    &-wrapper {
      margin: var(--spacing-8);
      padding: 0 var(--spacing-12);
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      h3 {
        @include textDisplayS(var(--text-gray-strongest), 700);
        margin-bottom: var(--spacing-10);
      }
    }
  }
}
