@import "@/assets/scss/styles";

.button-dfm {
  height: 100%;
  box-shadow: none;
  padding: var(--spacing-1-5) var(--spacing-4);
  &:disabled,
  .ant-btn-disabled {
    background-color: var(--bg-gray-weak);
    border-color: var(--border-gray-button);
    color: var(--text-gray-base) !important;
  }
}
.prevent-click {
  pointer-events: none;
}

.button-success {
  @include textS(var(--text-base-success), 600);
  min-width: 40px;
  border: 1px solid transparent;
  background-color: var(--bg-success-tonal-weaker);
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    @include textS(var(--text-base-success), 600);
    background-color: var(--bg-success-tonal-normal) !important;
    border: 1px solid var(--border-base-success);
  }
}

.button-warning {
  @include textS(var(--text-base-warning), 600);
  min-width: 40px;
  border: 1px solid transparent;
  background-color: var(--bg-warning-tonal-weaker);
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    @include textS(var(--text-base-warning), 600);
    background-color: var(--bg-warning-tonal-normal) !important;
    border: 1px solid var(--border-base-warning);
  }
}

.button-error {
  @include textS(var(--text-base-danger), 600);
  min-width: 40px;
  border: 1px solid transparent;
  background-color: var(--bg-brand-tonal-weaker);
  border-radius: var(--spacing-0-5);

  &:hover:not(:disabled):not(.ant-btn-disabled) {
    @include textS(var(--text-base-danger), 600);
    background-color: var(--bg-brand-tonal-normal) !important;
    border: 1px solid var(--border-base-danger);
  }
}
