@import "@/assets/scss/styles.scss";

.text-label {
  display: flex;
  margin-bottom: var(--spacing-1);
  &-medium {
    @include textL(var(--text-gray-stronger), 500);
  }
  &-small {
    @include textM(var(--text-gray-stronger), 500);
  }
  &-required {
    color: var(--text-base-danger);
  }
}

.error {
  display: flex;
  align-items: center;
  margin-top: var(--spacing-1);
  &-text {
    @include textS(var(--text-base-danger), 400);
  }
  &-icon {
    color: var(--text-base-danger);
    margin-right: var(--spacing-1);
  }
}

.warning {
  &-text {
    @include textS(var(--text-base-warning), 400);
  }
}