@import "@/assets/scss/styles.scss";

.form {
  &-section {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: var(--spacing-6);
    grid-row-gap: var(--spacing-6);
  }
  &-action {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: var(--spacing-10);
    gap: var(--spacing-5);
    &-button {
      width: 384px;
    }
    &-item {
      display: flex;
      justify-content: center;
      align-items: center;
      &-text {
        @include textL(#122b31, 500);
        margin-right: var(--spacing-2);
      }
      &-button {
        padding: 0 !important;
      }
    }
  }
  &-policy {
    display: flex;
    align-items: center;
    gap: var(--spacing-2);
    padding-top: var(--spacing-10);
    p {
      @include textM(var(--text-gray-stronger),400);
    }
    a {
      color: var(--text-base-brand);
    }
  }
}
.boder-bottom {
  border-bottom: 1px solid var(--border-normal);
  margin-bottom: var(--spacing-6);
  padding-bottom: var(--spacing-6);
}

.dropdown-city {
  max-height: 180px;
}