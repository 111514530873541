@import "@/assets/scss/styles";

.order-detail-item-box {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 0.5fr 0.5fr;
  column-gap: 16px;
  justify-content: space-between;
  padding: var(--spacing-3) 0;
  border-bottom: 1px solid var(--border-gray-normal);

  @media (max-width: 1380px) {
    grid-template-columns: 1.5fr 1fr 0.8fr 0.6fr 0.6fr;
  }
  @media (min-width: 1025px) and (max-width: 1266px) {
    grid-template-columns: 2.3fr 1fr 1fr 0.8fr 0.8fr;
  }
  @media (max-width: 1024px) {
    grid-template-columns: 2.4fr 0.9fr 1fr 0.9fr 0.7fr;
  }
  &-col-1 {
    display: flex;
    gap: var(--spacing-3);
    &-title {
      white-space: normal;
      overflow: hidden;
      word-wrap: break-word;
      text-overflow: ellipsis;
      max-width: 150px;
      @include textM(var(--text-gray-strongest), 600);
      padding-bottom: var(--spacing-1);
      @media (min-width: 1025px) and (max-width: 1440px) {
        max-width: 130px;
      }
    }
    &-size {
      width: fit-content;
      display: flex;
      align-items: center;
      p {
        padding: 0 !important;
        @include textM(var(--text-gray-normal), 400);
        padding-bottom: var(--spacing-2) !important;
      }
    }
    &-volume {
      width: fit-content;
      p {
        padding-bottom: 0 !important;
        @include textS(var(--text-gray-stronger), 400);
        padding-bottom: var(--spacing-2) !important;
      }
    }
    .order-detail-drawings {
      display: flex;
      cursor: default;
      align-items: center;
      flex-wrap: wrap;

      &-item {
        display: flex;
        align-items: center;
        gap: var(--spacing-1);
        border-radius: 99999px;
        padding: 0 var(--spacing-1);
        background-color: var(--bg-gray-weaker);

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @include textM(var(--text-gray-stronger), 500);
          padding: 0;
        }

        span {
          cursor: pointer;
        }
      }

      @media (min-width: 1501px) and (max-width: 1600px) {
        max-width: 150px;

        &-item {
          max-width: 150px;
        }
      }

      @media (min-width: 1401px) and (max-width: 1500px) {
        max-width: 120px;

        &-item {
          max-width: 120px;
        }
      }

      @media (max-width: 1400px) {
        max-width: 100px;

        &-item {
          max-width: 100px;
        }
      }

      @media (max-width: 1350px) {
        max-width: 80px;

        &-item {
          max-width: 80px;
        }
      }

      @media (max-width: 1280px) {
        max-width: 70px;

        &-item {
          max-width: 70px;
        }
      }

      @media (max-width: 1080px) {
        max-width: 70px;

        &-item {
          max-width: 70px;
        }
      }
    }

    @media (max-width: 1400px) {
      &-size {
        width: 100px;
      }
    }
    @media (max-width: 1280px) {
      &-title {
        max-width: 100px;
      }
      &-size {
        width: 90px;
      }
    }
    @media (max-width: 1080px) {
      &-title {
        max-width: 80px;
      }
    }
  }
  &-col-2 {
    p {
      @include textM(var(--text-gray-stronger), 400);
      padding-bottom: var(--spacing-1);
    }
    p {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px;
    }
    @media (max-width: 1600px) {
      p {
        max-width: 140px;
      }
    }
    @media (max-width: 1400px) {
      p {
        max-width: 120px;
      }
    }
    @media (max-width: 1080px) {
      p {
        max-width: 100px;
      }
    }
  }
  &-col-3 {
    p {
      text-align: center;
      @include textM(var(--text-gray-stronger), 400);
    }
  }
  &-col-4 {
    text-align: right;
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: space-between;
    &-price {
      p:nth-child(1) {
        @include fontSetup(var(--text-gray-stronger), 18px, 28px, normal, 700);
        padding-bottom: var(--spacing-1);
      }
      p:nth-child(2) {
        @include textS(var(--text-gray-normal), 400);
      }
    }
    &-badge {
      position: absolute;
      width: max-content;
      bottom: 0;
      right: 0;
    }
  }
  &-col-5 {
    align-self: self-start;
    max-width: 190px;
    p {
      padding-bottom: var(--spacing-1);
      @include textM(var(--text-gray-stronger), 400);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @media (min-width: 1601px) and (max-width: 1700px) {
      max-width: 180px;
    }
    @media (min-width: 1400px) and (max-width: 1600px) {
      max-width: 160px;
    }
    @media (min-width: 1301px) and (max-width: 1399px) {
      max-width: 120px;
    }
    @media (min-width: 1200px) and (max-width: 1300px) {
      max-width: 100px;
    }
    @media (min-width: 1024px) and (max-width: 1199px) {
      max-width: 100px;
    }
  }
}
