@import "@/assets/scss/styles.scss";

.shipping {
  &-wrapper {
    margin-top: var(--spacing-8);
    background-color: var(--bg-base-base);
    padding: var(--spacing-4) var(--spacing-4) var(--spacing-4) var(--spacing-4);
    &-title {
      @include textXXL(var(--text-gray-stronger),700);
      padding-bottom: var(--spacing-3);
    }
  }
  &-hint {
    display: flex;
    gap: var(--spacing-1);
    @include textM(var(--text-gray-normal), 400);
    margin-bottom: var(--spacing-6);
  }
  &-container {
    &-btn {
      display: flex;
      justify-content: end;
      &-edit {
        width: 104px;
      }
    }
  }
}