@import "@/assets/scss/styles.scss";

.content-sidebar {
  background-color: var(--bg-base-base);
  margin: var(--spacing-8) 0 0 var(--spacing-6);
  display: flex;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-5);
  padding: var(--spacing-3);
  border-radius: var(--spacing-0-5);
  border: 1px solid var(--border-gray-normal);
  &-avatar {
    border-radius: 50%;
    object-fit: cover;
    // &:hover {
    //   cursor: pointer;
    // }
  }
  &-item {
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-2);
    @include textL(var(--text-gray-normal), 500);
  }
  &-item-active {
    @include textL(var(--text-gray-stronger), 500);
    border-bottom: var(--spacing-0-5) solid var(--border-base-brand);
  }
  &-items {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }
  .user-infor {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: var(--spacing-1);
      @include textXXL(var(--text-gray-stronger), 600);
    }
    .upload-container {
      position: relative;
      // cursor: pointer;

      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        width: 108px;
        height: 108px;
      }

      .custom {
        background-color: white;
        position: absolute;
        bottom: 0;
        right: 6px;
        color: black;
        border-radius: 50%;
        z-index: 10;
        padding: var(--spacing-1);
        width: var(--spacing-6);
        height: var(--spacing-6);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          vertical-align: middle;
          width: 14px;
          height: 14px;
          padding: var(--spacing-0-5);
          object-fit: contain;
        }
      }
    }
  }
}
