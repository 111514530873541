@import "@/assets/scss/styles.scss";

.shipping-form {
    &-container {
        &-title {
            @include textM(var(--text-gray-strongest),700);
            padding-bottom: var(--spacing-3);
        }
        &-subtitle {
            @include textM(var(--text-gray-normal),700);
            padding: var(--spacing-3) var(--spacing-0);
            text-transform: uppercase;
        }
        &-row {
            display: flex;
            gap: var(--spacing-3);
            padding-bottom: var(--spacing-3);
        }
        &-col {
            width: 100%;
            div {
                .text-label {
                    @include textM(var(--text-gray-strongest),400);
                }
            }
        }
        &-btn {
            display: flex;
            gap: var(--spacing-2);
            justify-content: end;
            padding-top: var(--spacing-5);
            &-edit {
                width: 104px;
            }
        }
    }
}