@import "@/assets/scss/styles";
.shipping-method-box-partner {
  .loading {
    padding-top: 0 !important;
  }
  width: 416px;
  .title-method {
    @include textM(var(--text-gray-normal), 700);
    padding-bottom: var(--spacing-3);
  }
  &-item {
    display: grid;
    grid-template-columns: var(--spacing-3) 120px 148px 60px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: var(--spacing-3);
    border: 1px solid var(--border-gray-normal);
    padding: var(--spacing-2) var(--spacing-3);
    .img-box {
      img {
        width: 26px;
      }
      display: flex;
      gap: var(--spacing-3);
      align-items: center;
      &-name {
        @include textM(var(--text-gray-stronger), 600);
      }
    }
    .time-text {
      @include textM(var(--text-gray-normal), 400);
      border-right: 1px solid var(--border-gray-normal);
    }
    .price-text {
      @include textM(var(--text-gray-strongest), 600);
    }
  }
  .text-label-medium {
    @include textM(var(--text-gray-normal), 700);
    font-family: Open Sans, sans-serif !important;
  }
}
