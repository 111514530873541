@import '@/assets/scss/styles';

.modal-custom-request {
    width: 400px !important;
    &-header {
      padding-bottom: var(--spacing-4);
      @include textS(var(--text-gray-stronger),400);
      font-style: italic;
    }
  }

.manual-form {
    &-spacing {
        display: flex;
        flex-direction: column;
        gap: var(--spacing-4);
        > div {
            .text-label-medium {
                color:  var(--text-gray-normal) !important;
            }
        }
    }
    &-btn {
        align-self: center;
        padding-top: var(--spacing-4);
        &-next {
            width: 120px;
        }
    }
}

.file-render {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-gray-weaker);
    border-radius: var(--spacing-5);
    padding: var(--spacing-1) var(--spacing-3);
    img {
        cursor: pointer;
    }
    p {
        @include textM(var(--text-gray-stronger), 400);
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
