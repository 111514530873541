@import "@/assets/scss/styles";

.abandoned-cart-item-box {
  background-color: var(--bg-base-base);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--border-gray-strong);
  padding-bottom: var(--spacing-3);
  margin-bottom: var(--spacing-6);

  &:last-child {
    border-bottom: none;
  }
  p {
    flex: 1;
  }

  p:nth-child(1) {
    @include textL(var(--text-base-brand), 600);
  }

  p:nth-child(2) {
    .custom-badge-tonal-extrasmall-role .custom-badge-container {
      @include textM(var(--text-gray-stronger), 400);
    }
  }

  p:nth-child(3),
  p:nth-child(4) {
    @include textL(var(--text-gray-stronger), 600);
  }
}

.loading-part {
  display: flex;
  align-items: center;
  gap: 5px ;
  .loading-wrapper {
    display: flex;
    align-items: center;
    .loading {
      padding: 0;
      img {
        width: 10px;
      }
    }
  }
}