@import "@/assets/scss/styles.scss";

.forgot-pw-page {
  padding: 180px 132px var(--spacing-0) 132px;
  &-email {
    @include textL(var(--text-gray-stronger), 500);
    &-title {
      @include textL(var(--text-gray-strongest), 500);
    }
  }
  &-title {
    margin-bottom: var(--spacing-10);
    @include textDisplayS(var(--text-gray-strongest), bold);
  }

  &-notify {
    @include textL(var(--text-gray-strongest), 500);
    p {
      @include textL(var(--text-gray-normal), 500);
      margin-top: var(--spacing-10);
      margin-bottom: var(--spacing-5);
    }
  }

  &-confirm {
    @include textL(var(--text-gray-strongest), 500);
  }

  &-btn {
    @include textS(var(--text-gray-normal), 600);
    &-text {
      @include textS(var(--text-gray-normal), 600);
      cursor: pointer;
    }
  }

  @media (max-width: 1200px) {
    padding: 180px 100px var(--spacing-0) 100px;
  }

  @media (max-width: 992px) {
    padding: 180px var(--spacing-20) var(--spacing-0) var(--spacing-20);
  }

  @media (max-width: 768px) {
    padding: 180px var(--spacing-5) var(--spacing-0) var(--spacing-5);
  }

  @media (max-width: 525px) {
    padding: 30px var(--spacing-5) var(--spacing-0) var(--spacing-5);
  }
}

.forgot-pw-page .anticon-check-circle svg path {
  fill: var(--bg-success-solid-normal) !important;
}

.btn-resend-email {
  padding: var(--spacing-1) var(--spacing-2) !important;
  @include textL('', 600);
}