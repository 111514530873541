@import '@/assets/scss/styles';
.input-view {
    background-color: var(--bg-base-bg);
    border: 1px solid var(--border-gray-normal);
    @include textM(var(--text-gray-stronger), 500);
}

.input-view-small {
    padding: var(--spacing-2);
}

.input-view-medium {
    padding: 14px var(--spacing-3);
}